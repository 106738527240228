import React from "react";
import "./header.scss";
import {
  Box,
  AppBar,
  Toolbar,
  IconButton,
  Typography,
  Menu,
  MenuItem,
  FormControl,
  Select,
} from "@mui/material";
import { UserIcon, LoadData, Dashboard, Optimize, Result, MMXLogo, SelectedDataset } from "../../../../assets/icons/Icons";
import { useAuth } from "../../../../utils/auth/auth.guard";
import { useNavigate } from "react-router";
import { useTrainer } from "../../services/trainer.service";
import { useLocation } from "react-router-dom";
import { Services } from "../../../shared/services";
import { IStepWiseDataConfig, IfilterData } from "../../../../utils/interfaces/interface";
import { DASHBOARD_DATASET_FILTER } from "../../../../assets/data/json";
import { ApiEndPoints } from "../../../../utils/enums/apiendpoints";

export function Header() {
  const auth = useAuth();
  const navigate = useNavigate();
  const trainer = useTrainer();
  const location = useLocation();

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [loading, setLoading] = React.useState(false);
  // const [datasetFilterData, setDatasetFilterData] = React.useState<IfilterData>();
  const [datasetName, setDatasetName] = React.useState<string>(trainer.datasetName);

  const AppService = React.useContext(Services.AppService);


  const handleMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
    // auth.logout();
    // navigate("/login");
  };
  const handleLogout=()=>{
    handleClose()
    auth.logout()
    // navigate("/login");
  };

  // const getDashbaordFilterData = async () => {
    // setLoading(true);
    // AppService.API_HANDLER({
    //   auth,
    //   requestMethod: "get",
    //   url: ApiEndPoints.DASHBOARD_DATASET_LIST.toString(),
    //   useGlobalLoader: false,
    //   showErrorMessage: true,
    //   showSuccessMessage: false,
    //   fetchFromApi: true,
    //   LOCAL_RESPONSE: DASHBOARD_DATASET_FILTER,
    //   successCb: (res: any) => {
        // setDatasetFilterData(trainer.savedDatasets);
        // setLoading(false);
      // },
      // failedCb: () => {
      //   setLoading(false);
      // },
    // });
  // };
  
  // React.useEffect(() =>{
  //   getDashbaordFilterData();
  // }, [])

  React.useEffect(() => {
    trainer.setDatasetName(datasetName);
  }, [datasetName])
  return (
    <Box>
      <AppBar position="sticky">
        <Toolbar variant="dense" className="d-flex justify-content-between">
          <div className="d-flex">
            <IconButton
              size="large"
              edge="start"
              color="inherit"
              aria-label="menu"
              className="logo-btn"
              onClick={() => navigate("/landing-dashboard")}
            >
              <MMXLogo />
            </IconButton>
            {location.pathname === "/dashboard" ? (
              <div>
                {/* {console.log(trainer.savedDatasets, trainer.datasetName)} */}
                {trainer.savedDatasets
                  ? trainer.savedDatasets.map((filter: IfilterData) => (
                      <FormControl>
                        <Select
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          value={trainer.datasetName}
                          onChange={(event) =>
                            setDatasetName(event?.target.value)
                          }
                          className="form-control"
                        >
                          {filter.options.map((item: any) => (
                            <MenuItem value={item.value}>{item.title}</MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    ))
                  : null}
              </div>
            ) : (
              trainer.datasetName !== "" ? (
              <span className="dataName">
                <SelectedDataset />
                {trainer.savedDatasets[0].options.find((d: any) => d.value == trainer.datasetName).title}
                {/* {trainer.datasetName} */}
              </span>
              ):null
            )}
          </div>

          <div className="user-profile">
            <IconButton
              className={location.pathname === "/load-data" ? "active-btn" : ""}
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              color="inherit"
              onClick={() => navigate("/load-data")}
            >
              <LoadData />
              <Typography>Load Data</Typography>
            </IconButton>
            <IconButton
              className={location.pathname === "/dashboard" ? "active-btn" : ""}
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              color="inherit"
              onClick={() => trainer.datasetName != '' && navigate("/dashboard")}
            >
              <Dashboard />
              <Typography>Dashboard</Typography>
            </IconButton>
            <IconButton
              className={location.pathname === "/optimize" ? "active-btn" : ""}
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              color="inherit"
              onClick={() => trainer.datasetName != '' && navigate("/optimize")}
            >
              <Optimize />
              <Typography>Optimize</Typography>
            </IconButton>
            <IconButton
              className={location.pathname === "/result" ? "active-btn" : ""}
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              color="inherit"
              onClick={() => trainer.datasetName != '' && navigate("/result")}
            >
              <Result />
              <Typography>Result</Typography>
            </IconButton>
            <div className="border-start px-1 border-end">
              <IconButton
                size="large"
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={handleMenu}
                color="inherit"
              >
                <UserIcon />
                {/* <Typography>{auth.userDetails.firstName} {auth.userDetails.lastName}</Typography> */}
              </IconButton>
              <Menu
                id="menu-appbar"
                anchorEl={anchorEl}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "right",
                }}
                keepMounted
                transformOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                open={Boolean(anchorEl)}
                onClose={handleClose}
              >
                <MenuItem onClick={handleLogout}>Logout</MenuItem>
              </Menu>
            </div>
          </div>
        </Toolbar>
      </AppBar>
    </Box>
  );
}
