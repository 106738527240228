// /src/hooks/useAuth.tsx
import React, { useState, createContext, useContext } from "react";
import { IStepWiseDataConfig } from "../../../utils/interfaces/interface";

// Create the context
const TrainerContext = createContext<any>(null);

export const TrainerProvider = ({
  children,
}: {
  children: React.ReactElement;
}) => {
  const [datasetName, setDatasetName] = useState("");
  const [experimentDetails, setExperimentDetails] = useState({});
  const [uploadLoadData, setUploadLoadData] = useState({
    "marketing_spend": "",
    "historical_sales": "",
    "response_curve": "",
    "forecasted_sales": "",
    "adstock_data": "",
    "channel_fee_percentage": "",
    "events_calendar": ""
  });
  const [saveSuccess, setSaveSuccess] = useState(false);
  const [latestDataset, setLatestDataset] = useState(null);
  const [savedDatasets, setSavedDatasets] = useState(null);
  const [processId, setProcessId] = useState(null);
  const [geoData, setGeoData] = useState(null);
  const [channelData, setChannelData] = useState(null);
  const [stepWiseData, setStepWiseData] = useState<IStepWiseDataConfig>({
    load: {
        active: true,
      },
      dashboard: {
        active: false,
      },
      optimize: {
        active: false,
      },
      results: {
        active: false,
      },
  })

  React.useEffect(() => {
    const tempStepWiseData: IStepWiseDataConfig | any = {...stepWiseData}
    Object.keys(stepWiseData).forEach((step: string) => {
        if (datasetName.length) {
            tempStepWiseData[step].active = true
        } else if (step == 'load') {
            tempStepWiseData[step].active = true
        } else {
            tempStepWiseData[step].active = false
        }
    })
    // console.log(tempStepWiseData)
    setStepWiseData(tempStepWiseData)
  }, [datasetName])

  return (
    <TrainerContext.Provider
      value={{
        datasetName,
        setDatasetName,
        experimentDetails,
        setExperimentDetails,
        saveSuccess,
        setSaveSuccess,
        stepWiseData, 
        setStepWiseData,
        uploadLoadData,
        setUploadLoadData,
        latestDataset,
        setLatestDataset,
        savedDatasets,
        setSavedDatasets,
        processId,
        setProcessId,
        setChannelData,
        channelData,
        setGeoData,
        geoData
      }}
    >
      {children}
    </TrainerContext.Provider>
  );
};

export const useTrainer = () => useContext(TrainerContext);
