export const data = [
  {
    title: "Load Data",
    desc: "Upload granular sales, spend, input data",
    active: true,
    slug: "load",
    url: "/load-data",
  },
  {
    title: "Dashboard",
    desc: "Visual analytics, ROIs, MROIs; slice & dice data.",
    active: false,
    slug: "dashboard",
    url: "/dashboard",
  },
  {
    title: "Optimize",
    desc: "Set Fixed Spend, Target Attainment, or Maximum Attainment scenarios.",
    active: false,
    slug: "optimize",
    url: "/optimize",
  },
  {
    title: "Results",
    desc: "View, download, and compare scenario results.",
    active: false,
    slug: "results",
    url: "/result",
  },
];
