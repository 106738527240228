export const data = {
  status: "200",
  message: "successfully featch the data.",
  data: {
    metaData: {
      seriesData: [
        { dataKey: "Aug23", label: "Aug 23" },
        { dataKey: "Sept23", label: "Sept 23" },
        { dataKey: "Oct23", label: "Oct 23" },
        { dataKey: "Nov23", label: "Nov 23" },
        { dataKey: "Dec23", label: "Dec 23" },
        { dataKey: "Jan24", label: "Jan 24" },
      ],
    },
    chartData: [
      {
        Aug23: 10,
        Sept23: 5,
        Oct23: 59,
        Nov23: 57,
        Dec23: 86,
        Jan24: 21,
        channel: "TV",
      },
      {
        Aug23: 20,
        Sept23: 53,
        Oct23: 50,
        Nov23: 52,
        Dec23: 78,
        Jan24: 28,
        channel: "OOH",
      },
      {
        Aug23: 10,
        Sept23: 5,
        Oct23: 47,
        Nov23: 53,
        Dec23: 106,
        Jan24: 41,
        channel: "Google",
      },
      {
        Aug23: 10,
        Sept23: 0,
        Oct23: 54,
        Nov23: 56,
        Dec23: 92,
        Jan24: 73,
        channel: "Meta",
      },
      {
        Aug23: 10,
        Sept23: 15,
        Oct23: 57,
        Nov23: 69,
        Dec23: 92,
        Jan24: 99,
        channel: "OTT",
      },
      {
        Aug23: 82,
        Sept23: 5,
        Oct23: 60,
        Nov23: 63,
        Dec23: 103,
        Jan24: 144,
        channel: "Print",
      },
      {
        Aug23: 60,
        Sept23: 55,
        Oct23: 65,
        Nov23: 60,
        Dec23: 106,
        Jan24: 249,
        channel: "Digital",
      },
    ],
  },
};
