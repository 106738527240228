import { createTheme } from '@mui/material';

export const theme = createTheme({
    palette: {
        mode: 'light',
        divider: '#98AAB8',
        primary: {
            main: '#08978E',
            contrastText: '#FFFFFF'
        },
        secondary: {
            main: '#E33E7F'
        }
    },
    typography: {
        fontSize: 12,
    },
})