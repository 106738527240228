import { Navigate, useLocation } from "react-router-dom";
import React from "react";
import { useAuth } from "./auth.guard";

const Protected = ({ children }: { children: React.ReactElement }) => {
  const auth = useAuth()!;
  const location = useLocation();
  const [isUserAuthenticated, setIsUserAuthenticated] = React.useState<boolean>(auth.authed)
  // console.log('called auth guard')
  React.useEffect(() => {
    // console.log('inside route guard useeffect: ', {auth})
    setIsUserAuthenticated(false)
    if (!auth.userDetails) {
      const awaitUserDetails = async () => {
        const userDetails = await auth.getUserDetails();
        // console.log({userDetails})
        setIsUserAuthenticated(userDetails)
      }
      awaitUserDetails();
      // console.log('inside route guard useeffect: ',{userDetails: auth.userDetails})
    } else {
      setIsUserAuthenticated(true)
    }
  }, [])
  // console.log({isUserAuthenticated})
  if (isUserAuthenticated) {
    if (!auth.authed && !location.pathname.startsWith("/login")) {
    //  console.log('hellllooo')
      return <Navigate to="/login" replace />
    }
    if (auth.authed && location.pathname.startsWith("/login")) {
      return <Navigate to="/landing-dashboard" replace />
    }
  }
  return children;
};
export default Protected;
