import React from "react";
import './login.scss';
import Button from "@mui/material/Button";
import { RightArrowIcon } from "../../../../assets/icons/Icons";
import { useAuth } from "../../../../utils/auth/auth.guard";
import { useNavigate, useLocation } from "react-router-dom";
import { Services } from "../../../shared/services";
import { FormControl, Input, InputLabel } from '@mui/material';
import { MMXMainLogo } from "../../../../assets/icons/Icons";

export default function Login() {
    // console.log('dummy')
    // const [username, setUsername] = React.useState("");
    // const [password, setPassword] = React.useState("")

    // const auth = useAuth()!;
    // const navigate = useNavigate();

    // const AppService = React.useContext(Services.AppService);


    // function useQuery() {
    //     const { search } = useLocation();
    //     return React.useMemo(() => new URLSearchParams(search), [search]);
    // }

    const loginHandler = () => {
        // const time = new Date(Date.now() + 60000)
        // // document.cookie = `prototype=vrt; Domain=zsservices.com; Expires=${expiryTiming}; Path=/`
        // AppService.API_HANDLER({
        //     auth,
        //     requestMethod: "post",
        //     url: "",
        //     payload: {
        //         username: username,
        //         password: password
        //     },
        //     useGlobalLoader: true,
        //     showErrorMessage: true,
        //     showSuccessMessage: false,
        //     fetchFromApi: true,
        //     LOCAL_RESPONSE: {
        //         data: { username: username, password: password }
        //     },
        //     successCb: (res: any) => {
        //         auth.login(res.data);
        //         setTimeout(() => {
        //             navigate("/landing-dashboard");
        //         }, 250);
        //     },
        //     failedCb: () => {
        //     }
        // });
        window.location.href = "https://idm.zsservices.com/Triumph/IdentityManager/app/Web/login.aspx?returnurl=https%3A%2F%2Fmixharbour.triumph.zsservices.com/login"
    }

    return (
        <React.Fragment>
            <div className="login-page">
                <div className="logo">
                    <MMXMainLogo />
                </div>
                <Button className="mt-3" onClick={loginHandler} variant="contained" endIcon={<RightArrowIcon />}>
                    Login
                </Button>
            </div>
        </React.Fragment>
    )
}