export default class HTTP {
  public async get(url: string, options?: any): Promise<any> {
    options = { method: "GET", ...options };
    return await this._request(url, options);
  }
  public async post(url: string, data: any, options: any = {}): Promise<any> {
    options = { method: "POST", ...options, body: data };
    return await this._request(url, options);
  }
  public async put(url: string, data: any, options: any = {}): Promise<any> {
    options = { method: "PUT", ...options, body: data };
    return await this._request(url, options);
  }
  public async delete(url: string, data?: any, options?: any): Promise<any> {
    if (!options) {
      options = {};
    }
    options = { method: "DELETE", ...options, body: data };
    return await this._request(url, options);
  }
  private async _request(url: string, options: any = {}) {
    const user: any = localStorage.getItem("user");
    let request = {
      ...options,
      credentials: "include",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json;charset=UTF-8",
        Authorization: user ? JSON.parse(atob(user))._t : null,
      },
    };
    if (options.method === "PUT") {
      request = {
        ...options,
        credentials: "include",
        headers: {
          Accept: "application/json",
        },
      };
    }
    if (options.method === "PUT") {
      request.body = options.body;
    } else if (options.method !== "GET") {
      request.body = JSON.stringify(options.body);
    }
    const finalValuePromise = new Promise((res, rej) => {
      fetch(url, request)
        .then(async (response: any) => {
          if (!response.ok) {
            const data = await response.json();
            // console.log(data);
            const error: any = new Error(
              data?.detail ? data.detail : "Something went wrong..!",
              {
                cause: { data },
              }
            );
            error.status = response.status;
            throw error;
          }
          const redirect = [...response.headers].find(
            (header) => header[0] === "x-redirect"
          );
          if (redirect && redirect[1]) {
            window.location.href = redirect[1];
          }
          return response;
        })
        .then((response) => response.json())
        .then((data) => {
          res(data);
        })
        .catch((error) => {
          if (error.data) {
            error.data.then((d: any) => {
              rej({ ...d, status: error.status });
            });
          } else {
            rej(error);
          }
        });
    });
    return finalValuePromise;
  }
}
