import React from "react";
import {
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
} from "@mui/material";
import { CloseIcon} from "../../../assets/icons/Icons";
interface DialogContentProps {
  onClose: () => void;
  constraint: any;
}

const ConstraintPopup: React.FC<DialogContentProps> = ({
  onClose,
  constraint,
}) => {
  return (
    <>
      <div className="small-popup">
        <div className="dialog-top">
          <DialogTitle className="p-0">{constraint.geoName}</DialogTitle>
          <Button onClick={onClose} className="p-0"><CloseIcon /></Button>
        </div>
        <DialogContent>
          <table className="w-100">
            <thead>
              <tr>
                {constraint?.header
                  ? constraint.header.length > 0 &&
                  constraint.header.map((header: string) => <th>{header}</th>)
                  : null}
              </tr>
            </thead>
            <tbody>
            {
              constraint?.channelData.map((data: any) => (
                <tr>
                  <td>{data.channelName}</td>
                  <td>{data.minBudget}</td>
                  <td>{data.MaxBudget}</td>
                </tr>
              ))
            }
            </tbody>

          </table>
        </DialogContent>
      </div>
    </>
  );
};

export default ConstraintPopup;
