export const data = {
  data: {
    fixed_budget: {
      section: "Budget Optimization",
      fixed_budget_form_config: [
        {
          title: "Experiment Name",
          key: "experiment_name",
          value: "",
          hint: "",
          placeholder: "",
          type: "text_input",
          is_mandatory: true,
          options: [],
          pattern: "",
          class: "col-12",
        },
        {
          title: "Marketing Spend",
          key: "total_budget",
          value: "",
          hint: "",
          placeholder: "",
          type: "number",
          is_mandatory: true,
          options: [],
          pattern: "",
          class: "col-12",
        },
        // {
        //   title: "Marketing Contribution",
        //   key: "marketing_contribution",
        //   value: "",
        //   hint: "",
        //   placeholder: "",
        //   type: "text_input",
        //   is_mandatory: true,
        //   options: [],
        //   pattern: "",
        //   class: "col-6",
        // },
        {
          title: "Planning Period",
          key: "from",
          value: "",
          hint: "",
          placeholder: "From",
          type: "date",
          is_mandatory: true,
          options: [],
          pattern: "",
          class: "col-6",
        },
        {
          title: "",
          key: "to",
          value: "",
          hint: "",
          placeholder: "To",
          type: "date",
          is_mandatory: true,
          options: [],
          pattern: "",
          class: "col-6",
        },
        {
          title: "Select Channel",
          key: "channels",
          value: "",
          hint: "",
          placeholder: "",
          type: "multi_select",
          is_mandatory: true,
          options: [
            {
              title: "Select All",
              value: "Select All",
            },
            {
              title: "TV",
              value: "TV",
            },
            {
              title: "OHH",
              value: "OHH",
            },
            {
              title: "Google",
              value: "Google",
            },
            {
              title: "Meta",
              value: "Meta",
            },
            {
              title: "OTT",
              value: "OTT",
            },
            {
              title: "Print",
              value: "Print",
            },
            {
              title: "Digital",
              value: "Digital",
            },
          ],
          pattern: "",
          class: "col-6",
        },
        {
          title: "Select Geo",
          key: "geos",
          value: "",
          hint: "",
          placeholder: "",
          type: "multi_select",
          is_mandatory: true,
          options: [
            {
              title: "Select All",
              value: "Select All",
            },
            {
              title: "Maharastra",
              value: "Maharastra",
            },
            {
              title: "Karnataka",
              value: "Karnataka",
            },
            {
              title: "Delhi NCR",
              value: "Delhi NCR",
            },
            {
              title: "Gujarat",
              value: "Gujarat",
            },
            {
              title: "Andhra Pradesh",
              value: "Andhra Pradesh",
            },
            {
              title: "Goa",
              value: "Goa",
            },
          ],
          pattern: "",
          class: "col-6",
        },
      ],
      radio_option: [
        {
          name: "High-Level Constraints",
          key: "high_level",
        },
        {
          name: "Granular Constraints",
          key: "granular",
        },
      ],
    },
  },
};
