export const data = {
  status: "200",
  message: "successfully featch the data.",
  data: {
    table_headers: [
      {
        sNo: 1,
        name: "Channel",
        key: "channelName",
        hidden: false,
        isSort: true,
      },
      {
        sNo: 2,
        name: "ROI",
        key: "roi",
        hidden: false,
        isSort: true,
      },
      {
        sNo: 3,
        name: "mROI",
        key: "mroi",
        hidden: false,
        isSort: true,
      },
    ],
    table_data: [
      {
        channelName: "TV",
        roi: 9,
        mroi: 5,
      },
      {
        channelName: "OHH",
        roi: 7,
        mroi: 4,
      },
      {
        channelName: "Google",
        roi: 13,
        mroi: 6,
      },
      {
        channelName: "Meta",
        roi: 14,
        mroi: 8,
      },
      {
        channelName: "OTT",
        roi: 10,
        mroi: 70,
      },
      {
        channelName: "Print",
        roi: 19,
        mroi: 3,
      },
      {
        channelName: "Digital",
        roi: 4,
        mroi: 3,
      },
    ],
  },
};
