export const data = {
  status: "200",
  message: "successfully featch the data.",
  data: {
    metaData: [
      {
        visualType: {
          type: "vertical_group_bar_chart",
        },
        legends: {
          legend1: {
            label: "ROI",
            color: "#2A97C3",
          },
          legend2: {
            label: "mROI",
            color: "#074985",
          },
        },
        scale: {
          xaxis: {
            type: "band",
          },
          y1axis: {
            minValue: 0,
            maxValue: 25,
            label: "ROI",
          },
          y2axis: {
            minValue: 0,
            maxValue: 100,
            label: "mROI",
          },
        },
      },
    ],
    chartData: [
      {
        key: "TV",
        bar1: 9,
        bar2: 5,
      },
      {
        key: "OHH",
        bar1: 7,
        bar2: 4,
      },
      {
        key: "Google",
        bar1: 13,
        bar2: 6,
      },
      {
        key: "Meta",
        bar1: 14,
        bar2: 8,
      },
      {
        key: "OTT",
        bar1: 10,
        bar2: 70,
      },
      {
        key: "Print",
        bar1: 19,
        bar2: 3,
      },
      {
        key: "Digital",
        bar1: 4,
        bar2: 2,
      },
    ],
  },
};
