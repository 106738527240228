export const data = {
    status: "success",
    message: "succeeded",
    data: [
        {
            name: "Spend",
            value: "250 Cr"
        },
        {
            name: "Total Sales",
            value: "300 Cr"
        },
        {
            name: "Incremental Sales",
            value: "4050 Cr"
        },
        {
            name: "ROI",
            value: "15.1"
        },
        {
            name: "mROI",
            value: "10.6"
        }
    ]
  };
  