import { AuthProvider } from './utils/auth/auth.guard';
import { BrowserRouter } from 'react-router-dom';
import { AppModuleRoutes } from './app.routing';
import { ThemeProvider } from '@emotion/react';
import { CssBaseline } from '@mui/material';

import './App.css';
import { LightTheme } from './assets/themes';


function App() {
//  console.log('hi')
  return (
    <ThemeProvider theme={LightTheme}>
      <CssBaseline />
      <AuthProvider>
        <div className="App" style={{ height: "100vh" }}>
          <BrowserRouter>
            <AppModuleRoutes />
          </BrowserRouter>
        </div>
      </AuthProvider>
    </ThemeProvider>
  );
}

export default App;



