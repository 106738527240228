export default class CommonService {
  private httpManager: any;

  public theme = true;
  constructor(http: any) {
    this.httpManager = http;
  }

  public uploadFileToS3 = (url: string, file: File): Promise<unknown> => {
    //  console.log("inside uploadFileToS3 ", file, url);
    const formData = new FormData();
    formData.append("file", file);
    return fetch(url, {
      body: formData,
      method: "POST",
      credentials: "include",
    });
  };

  downloadFile = async (url: string) => {
    try {
      const response = await fetch(url, {
        method: "POST",
        credentials: "include",
      });
      return response;
    } catch (error) {
      console.error("Error downloading file:", error);
      throw error;
    }
  };
  public API_HANDLER = async ({
    auth,
    url,
    requestMethod,
    LOCAL_RESPONSE,
    successCb,
    failedCb,
    payload = {},
    showSuccessMessage = true,
    showErrorMessage = true,
    useGlobalLoader = true,
    fetchFromApi = false,
  }: {
    url: string;
    auth: any;
    requestMethod: "get" | "post" | "delete";
    fetchFromApi: boolean;
    LOCAL_RESPONSE: any;
    payload?: string | number | any | unknown;
    successCb?: Function | undefined;
    failedCb?: Function | undefined;
    showErrorMessage?: boolean;
    showSuccessMessage?: boolean;
    useGlobalLoader?: boolean;
  }) => {
    const errorHandler = (message: string) => {
      if (useGlobalLoader) {
        auth?.setLoading(false);
      }
      if (showErrorMessage) {
        auth?.setSnackBarDetails({
          message,
          type: "error",
        });
      }
      if (typeof failedCb === "function") {
        failedCb();
      }
    };

    const responseHandler = (res: any) => {
      //console.log("Response Body:", res)
      if (res.status === "failed") {
        errorHandler(String(res?.message || "Something went wrong..."));
      } else {
        if (typeof successCb === "function") {
          successCb(res);
        }
        if (useGlobalLoader) {
          auth?.setLoading(false);
        }
        if (showSuccessMessage && res.message) {
          auth?.setSnackBarDetails({
            message: res.message,
            type: "success",
          });
        }
      }
    };

    //console.log("**** New Request ****")
    //console.log("Request Type:", requestMethod)
    //console.log("Endpoint:", url)
    //console.log("Request Payload:")
    //console.log(JSON.stringify(payload, null, 4))
    try {
      if (useGlobalLoader) {
        auth?.setLoading(true);
      }
      if (auth?.API_MODE && fetchFromApi) {
        responseHandler(await this.httpManager[requestMethod](url, payload));
      } else {
        setTimeout(
          () => responseHandler(JSON.parse(JSON.stringify(LOCAL_RESPONSE))),
          auth?.LOCAL_RESPONSE_TIMEOUT
        );
      }
    } catch (e: any) {
      //  console.log(e);
      if (e.status === 403 || e.status === 401) {
        window.location.href = window.location.origin + "/login";
      } else {
        errorHandler("Something went wrong...");
      }
    }
  };
}
