export const data = {
    "status": "200",
    "message": "successfully featch the data.",
    "data": {
        "metaData": [
            {
                "visualType": {
                    "type": "line_chart"
                },
                "legends": {
                },
                "scale":{
                    "xaxis": {
                        "minValue":0,
                        "maxValue": 100,
                        "label": "Spend"
                    },
                    "yaxis": {
                        "minValue":0,
                        "maxValue": 2,
                        "label": "Incremental Sales"
                    }
                    
                }
            }
        ],
        "chartData":[
            {
                "key": 0,
                "value": 2
                
            },
            {
                "key": 0.4,
                "value": 80
            },
            {
                "key": 0.5,
                "value": 50
            },
            {
                "key": 1,
                "value": 40
            },
            {
                "key": 1.2,
               "value": 32
            },
            {
                "key": 1.8,
                "value": 30
            },
            {
                "key": 2,
                "value": 20
            }
            
        ]
    }
}