import * as React from "react";
import { ChangeHandler, useForm } from "react-hook-form";
import {
  Checkbox,
  Chip,
  Dialog,
  FormControl,
  FormControlLabel,
  Input,
  InputLabel,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Radio,
  RadioGroup,
  Select,
  TextField,
  Button,
  FormGroup,
  DialogContent,
  CircularProgress
} from "@mui/material";
import {
  IFormField,
  IFormMultiSelect,
  IGranularConstraint,
  IMaximumAtt,
  IMaximumSelection,
  ISectionConstraint,
  ISelectAll,
  Radiooption,
} from "../../../../utils/interfaces/interface";
import { UploadIcon, DownloadSchemaIcon,SuccessIcon } from "../../../../assets/icons/Icons";
import { useAuth } from "../../../../utils/auth/auth.guard";
import { Services } from "../../../shared/services";
import { OPTIMIZE_CHANNEL_LEVEL_CONSTRAINT, OPTIMIZE_GEO_LEVEL_CONSTRAINT, OPTIMIZE_GRANULAR_CONSTRAINT } from "../../../../assets/data/json";
import ConstraintPopup from "../../../shared/components/ConstraintPopup";
import { ApiEndPoints } from "../../../../utils/enums/apiendpoints";
import { useNavigate } from "react-router-dom";
import { useTrainer } from "../../services/trainer.service";



type FormData = {
  experiment_name: string;
  total_budget: string;
  marketing_contribution: string;
  from: Date;
  to: Date;
  channel: string[];
  geo: string[];
  total_target: string;
  overall_mroi_target: string;
  second_section?: string;
};

// Adapter function for onChange event

const CustomForm: React.FC<{
  currentSection: string;
  sectionName: string;
  formData: IFormField[];
  secondSection?: Radiooption[];
  maximumAtt?: IMaximumAtt[];
  formConfigDict?:IFormField[];
}> = ({ currentSection, sectionName, formData, secondSection, maximumAtt,formConfigDict }) => {
  const {
    register,
    formState: { errors },
  } = useForm<FormData>();

  const [multiSelectvalue, setMultiSelectValue] =
    React.useState<IFormMultiSelect>({
      channel: [],
      geo: [],
    });
  const trainer = useTrainer()!;
  const [selectAll, setSelectAll] = React.useState<ISelectAll>({
    allChannelSelected: false,
    allGeoSelected: false,
  });
  const [radioValue, setRadioValue] = React.useState("high_level");
  const [files, setFiles] = React.useState<Array<File | null>>([null, null, null])
  const [channelLevelConstraint, setChannelLevelConstraint] = React.useState<ISectionConstraint>();
  const [geoLevelConstraint, setGeoLevelConstraint] = React.useState<ISectionConstraint>();
  const [granularConstraint, setGranularConstraint] = React.useState<any>()
  const [openDialog, setOpenDialog] = React.useState(false);
  const [constraintData, setConstraintData] = React.useState<any>(null);
  const [maximumSelection, setMaximumSelection] = React.useState<IMaximumSelection>()
  const [AllMaximumSelection, SetAllMaximumSelection] = React.useState([false, false])
  const [openRunPopup, setRunDialog] = React.useState(false);
  const [maximumAttSecondSec, setMaximumAttSecondSec] = React.useState<any>(maximumAtt);
  const [formDataNew, setFormDataNew] = React.useState<any>(formData);
  const [validationResponse, setValidationResponse] = React.useState<string>('');

  const [channelS3Location, setchannelS3Location] = React.useState<string>('');
  const [geoS3Location, setgeoS3Location] = React.useState<string>('');
  const [granularS3Location, setgranularS3Location] = React.useState<string>('');
  const [formLoader, setFormLoader] = React.useState<boolean>(false);
  const [constraintsLoader, setConstraintsLoader] = React.useState<boolean>(false);


  const AppService = React.useContext(Services.AppService);
  const auth = useAuth()!;
  const navigate = useNavigate();

  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };

  const uploadFile = async (index: number, filesData: any) => {
    let fileName = ''
    if (currentSection === 'Budget Optimization') {
      switch (index) {
        case 0:
          fileName = 'channel'
          break;
          case 1:
          fileName = 'geo'
          
          break;
          
          default:
          fileName = 'granular'
          break;
      }
    } else {
      switch (index) {
      case 0:
          fileName = 'channel'
          break;
          case 1:
          fileName = 'geo'
          
          break;
          
          default:
          fileName = 'granular'
          break;
      }
    }
    await AppService.uploadFileToS3(ApiEndPoints.OPTIMIZE_UPLOAD_SCHEMA + "/?filename="+fileName , filesData[index]).then(async (res: any)=> {
      return {data: await res.json(), status: res.status}
    }).then((res: any) => {
      setConstraintsLoader(false)
      if (res.status === 403 || res.status === 401) {
        window.location.href = window.location.origin + "/login";
        // auth?.setSnackBarDetails({
        //   message: 'Session expired. Redirecting to login',
        //   type: "error",
        // });
      }
      if (res.status == 200) {
      //  console.log(res.data)
        index === 0 ? setChannelLevelConstraint(res.data.data) : index === 2 ? setGranularConstraint(res.data.data) : setGeoLevelConstraint(res.data.data)
        index === 0 ? setchannelS3Location(res.data.s3_location_key) : index === 2 ? setgranularS3Location(res.data.s3_location_key) : setgeoS3Location(res.data.s3_location_key)
      } else {
        filesData[index] = null
        setFiles(filesData);
      }
    })
    .catch((err) => {
    //  console.log(err)
      filesData[index] = null
      setFiles(filesData);
    });
  };

  const handleDownloadSchema = (fileName: string) => {
    try {
      setConstraintsLoader(true)
      AppService.downloadFile(ApiEndPoints.OPTIMIZE_DOWNLOAD_SCHEMA.toString() + "?filename=" + fileName).then(async response => {
        setConstraintsLoader(false)
        if (response.status === 403 || response.status === 401) {
          window.location.href = window.location.origin + "/login";
          // auth?.setSnackBarDetails({
          //   message: 'Session expired. Redirecting to login',
          //   type: "error",
          // });
        }
        if (response.status == 200) {
          // Extract filename from Content-Disposition header
          const contentDisposition = response.headers.get("content-disposition");
          const filenameMatch = contentDisposition && contentDisposition.match(/filename="(.+)"$/);
          const suggestedFileName = filenameMatch ? filenameMatch[1] : fileName + ".csv";
      
          // Create a Blob from the response data
          const blob =  await response.blob();
      
          // Create a download link
          const url = URL.createObjectURL(await blob);
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", suggestedFileName);
          document.body.appendChild(link);
      
          // Trigger the download
          link.click();
      
          // Clean up
          document.body.removeChild(link);
          URL.revokeObjectURL(url);
      
        }
        
      })
    }
    catch (error) {
      console.error("Error downloading file:", error);
    }
  }

  const handleMultiSelectChange = (key: string, value: string[], index: number) => {
    let newFilters: IFormMultiSelect = { ...multiSelectvalue };
    let newSelectAll: ISelectAll = { ...selectAll };

    const allTitles = formData.reduce((acc: string[], field: IFormField) => {
      if (field.type === "multi_select" && field.options && field.key === key) {
        acc.push(...field.options.map((option) => option.title));
      }
      return acc;
    }, [] as string[]);

    if (value.includes("Select All")) {
      newFilters[key] = allTitles;
      newSelectAll[`all${key.charAt(0).toUpperCase() + key.slice(1)}Selected`] =
        true;
    } else {
      newFilters[key] = value.filter((v) => v !== "all" && v !== "Select All");
      newSelectAll[`all${key.charAt(0).toUpperCase() + key.slice(1)}Selected`] =
        false;
    }


    // console.log(newFilters, formData[index])
    formData[index].value = newFilters[formData[index].key];
    setFormDataNew(formData);
    setMultiSelectValue(newFilters);
    setSelectAll(newSelectAll);
  };

  const handleRadioChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRadioValue((event.target as HTMLInputElement).value as string);
  };

  const handleFileChange =
    (index: number) =>
      (e: React.ChangeEvent<HTMLInputElement>) => {
        const selectedFile = e.target.files && e.target.files[0];
        // Check if a file is selected and its size is less than 50 MB
        if (selectedFile && selectedFile.size <= 50 * 1024 * 1024) {
          const newFiles = [...files];
          newFiles[index] = selectedFile;
          setFiles(newFiles);
          setConstraintsLoader(true)
          uploadFile(index, newFiles);
        } else {
          alert("Please select a file less than 50 MB.");
        }
      };

  const fileInputRefs = Array.from({ length: 3 }, () =>
    React.createRef<HTMLInputElement>()
  );

  const OpenConstraintPopup = (constraint: any) => {
    setOpenDialog(true);
    setConstraintData(constraint)
  }

  const CustomDialog = (
    <Dialog open={openDialog} onClose={() => setOpenDialog(false)}>
      {/* Step 5: Use the DialogContentComponent for the dialog content */}
      <ConstraintPopup onClose={() => setOpenDialog(false)} constraint={constraintData} />
    </Dialog>
  );

  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>, title: string,option: any,index: number) => {
    // if (title.includes('Channel')) {
       if (maximumAtt) {
        if (option.value === 'Select All') {
          // Handle 'Select All' case
          maximumAtt[index].options.forEach((opt: any) => {
            opt.checked = event.target.checked;
            if (event.target.checked) {
              // Add to value array if checked
              if (!maximumAtt[index].value.includes(opt.value) && opt.value !== 'Select All') {
                maximumAtt[index].value.push(opt.value);
              }
            } else {
              // Remove from value array if unchecked
              const valueIndex = maximumAtt[index].value.indexOf(opt.value);
              if (valueIndex > -1) {
                maximumAtt[index].value.splice(valueIndex, 1);
              }
            }
          });
        } else {
          // Handle individual option case
          option.checked = event.target.checked;
          if (event.target.checked) {
            // Add to value array if checked
            if (!maximumAtt[index].value.includes(option.value)) {
              maximumAtt[index].value.push(option.value);
            }
          } else {
            // Remove from value array if unchecked
            const valueIndex = maximumAtt[index].value.indexOf(option.value);
            if (valueIndex > -1) {
              maximumAtt[index].value.splice(valueIndex, 1);
            }
          }
    
          // Check if all options are selected to check/uncheck 'Select All'
          if (!maximumAtt[index].options.filter(opt => opt.value !== 'Select All').find(opt => !opt.checked)) {
            maximumAtt[index].options.forEach((opt: any) => {
              if (opt.value === 'Select All') {
                opt.checked = true;
              }
            });
          } else {
            maximumAtt[index].options.forEach((opt: any) => {
              if (opt.value === 'Select All') {
                opt.checked = false;
              }
            });
          }
        }
      
      maximumAtt && setMaximumAttSecondSec([...maximumAtt])
     }
  };

  const handleTextInputChange = (e: any, index: number) => {
    formData[index].value = e.target.value
    setFormDataNew([...formData])
  }
  const handleDisableSubmitButton = () => {
    // let isButtonDisabled = true
    console.log('hello')
    for (let i = 0; i<formData.length; i++) {
      const data = formData[i];
      if(data.is_mandatory) {
        // console.log(data, (!data.value || !data.value.length))
        if ((!data.value || !data.value.length))
          {
            return (!data.value || !data.value.length)
          }
      }
    }
    
    if(currentSection === "Budget Optimization" )
    // || currentSection=== "Target Attainment"
        {
             if (!granularConstraint) {return true}
        
         }
    return false;
  }

  const onRunClick = (maximumAttData: IMaximumAtt[]) => {
    let payload: any = {
      dataset_name: trainer.savedDatasets[0].options.find((d: any) => d.value == trainer.datasetName).title,
      "dataset_id": trainer.datasetName,
      "experiment_name": formData.find((d: any) => d.key == 'experiment_name')?.value,
      "total_budget": formData.find((d: any) => d.key == 'total_budget')?.value ? formData.find((d: any) => d.key == 'total_budget')?.value : 0,
      "total_target": formData.find((d: any) => d.key == 'total_target')?.value ? formData.find((d: any) => d.key == 'total_target')?.value : 0,
      "overall_mroi_target": formData.find((d: any) => d.key == 'overall_mroi_target')?.value ? formData.find((d: any) => d.key == 'overall_mroi_target')?.value : 0,
      "marketing_contribution": 1,
      "planning_period_start_date": formData.find((d: any) => d.key == 'from')?.value,
      "planning_period_end_date": formData.find((d: any) => d.key == 'to')?.value,
      "channel_location": channelS3Location,
      "geo_location": geoS3Location,
      "granular_location": granularS3Location
    }
    if (currentSection === "Budget Optimization" || currentSection === "Target Attainment") {
        payload.selected_channels = formData.find((d: any) => d.key == 'channels')?.value;
        payload.selected_geos = formData.find((d: any) => d.key == 'geos')?.value;
        payload.marketing_contribution = currentSection === "Target Attainment"? formData.find((d: any) => d.key == 'marketing_contribution')?.value ? formData.find((d: any) => d.key == 'marketing_contribution')?.value : 0 : 1;
    }

    if (currentSection === "Maximum Attainment") {
        payload.selected_channels = maximumAttData.find((d: any) => d.key == 'channels')?.value;
        payload.selected_geos = maximumAttData.find((d: any) => d.key == 'geos')?.value;
    }
    setFormLoader(true)
    AppService.API_HANDLER({
      auth,
      requestMethod: "post",
      url: ApiEndPoints.OPTIMIZE_VALIDATION.toString() + `/?scenario_name=${currentSection === 'Budget Optimization' ? 'fixed_budget' : currentSection == 'Target Attainment' ? 'target_attainment' : 'maximum_attainment'}`,
      payload: payload,
      useGlobalLoader: false,
      showErrorMessage: true,
      showSuccessMessage: false,
      fetchFromApi: true,
      LOCAL_RESPONSE: {
        'status': 'success',
        'message': 'Validation Successful'
      },
      successCb: (res: any) => {
        setFormLoader(false)
        setValidationResponse(res.message);
        setRunDialog(true);  
      },
      failedCb: () => {
        setFormLoader(false)
      }
    });
  }
  const handleRunClick = () => {
  //  AppService.API_HANDLER({
    //  auth,
     // requestMethod: "post",
     // url: ApiEndPoints.OPTIMIZE_RUN.toString(),
     // payload: {},
     // useGlobalLoader: true,
     // showErrorMessage: true,
     // showSuccessMessage: false,
     // fetchFromApi: true,
     // LOCAL_RESPONSE: {
      //  'status': 'success',
    //    "message": "Experiment Triggered successfully",
     // },
      //successCb: (res: any) => {
    ///    if(res.status == 'success') {
      //    navigate('/result')
    //    }
     // },
      //failedCb: () => {
     //}
    //});
    navigate('/result')
    
  }

  const RunPopup = (
    <Dialog open={openRunPopup} onClose={() => setRunDialog(false)}>
      <div className="small-popup">
        <DialogContent className="validation-popup">
          <SuccessIcon />
          <p>{validationResponse}</p>
          <Button onClick={() => handleRunClick()} className="custom-button">Results</Button>
        </DialogContent>
      </div>
    </Dialog>
  );


  return (
    <div>
      {/* <form> */}
      {
        !formLoader ? 
          <>
            <div className="form-container">
              <div className="middle row mx-0">
                {formDataNew.map((field: any, i: number) => (
                  <div key={field.key} className={`${field.class}`}>
                    <FormControl
                      className="mb-3 input-custom w-100"
                      required={field.is_mandatory}
                      id={field.key}
                    >
                      <InputLabel htmlFor={field.key}>{field.title}</InputLabel>
                      {field.type === "text_input" && (
                        <OutlinedInput
                          id={field.key}
                          type="text"
                          required={field.is_mandatory}
                          {...register(field.key as keyof FormData, {
                            required: field.is_mandatory,
                          })}
                          onChange={(e) => handleTextInputChange(e, i)}
                          value={field.value}
                        />
                      )}
                      {field.type === "number" && (
                        <OutlinedInput
                          id={field.key}
                          type="number"
                          required={field.is_mandatory}
                          {...register(field.key as keyof FormData, {
                            required: field.is_mandatory,
                          })}
                          onChange={(e) => handleTextInputChange(e, i)}
                          value={field.value}
                        />
                      )}

                      {field.type === "multi_select" && (
                        <Select
                          labelId={field.key}
                          id={`filter-${field.key}`}
                          multiple
                          value={multiSelectvalue[field.key] || []}
                          input={<OutlinedInput />}
                          MenuProps={MenuProps}
                          renderValue={(selected) => (
                            <div style={{ display: "flex", flexWrap: "wrap" }}>
                              {selected.map((value) => (
                                <Chip
                                  key={value}
                                  label={value}
                                  style={{ margin: "2px" }}
                                />
                              ))}
                            </div>
                          )}
                          {...register(field.key as keyof FormData, {
                            required: field.is_mandatory,
                          })}
                          onChange={(event) =>
                            handleMultiSelectChange(
                              field.key,
                              event.target.value as string[],
                              i
                            )
                          }
                        >
                          {field.options.map(
                            (
                              data: { value: string; title: string },
                              index: number
                            ) => (
                              <MenuItem key={data.title} value={data.value}>
                                <Checkbox
                                  checked={
                                    multiSelectvalue[field.key] &&
                                      multiSelectvalue[field.key].length > 0
                                      ? multiSelectvalue[field.key].indexOf(
                                        data.title
                                      ) > -1
                                      : false
                                  }
                                />
                                <ListItemText primary={data.title} />
                              </MenuItem>
                            )
                          )}
                        </Select>
                      )}

                      {field.type === "date" && <OutlinedInput onChange={(e) => handleTextInputChange(e, i)} type="date" />}
                    </FormControl>
                  </div>
                ))}
              </div>
            </div>
            <div className="constraints-container">
              {secondSection ? (
                <FormControl component="fieldset">
                  <RadioGroup
                    row
                    aria-label="controlled-radio-buttons-group"
                    name="controlled-radio-buttons-group"
                    value={radioValue}
                    onChange={(event) => handleRadioChange(event)}
                  >
                    {secondSection.map((data: Radiooption) => (
                      <FormControlLabel
                        key={data.key}
                        value={data.key}
                        control={<Radio />}
                        label={data.name}
                      />
                    ))}
                  </RadioGroup>
                </FormControl>
              ) : null}
              {sectionName !== "maximum attainment" &&
                radioValue !== "" &&
                radioValue === "high_level" ? (
                <div className="d-flex row">
                  {
                    constraintsLoader ? 
                      <div style={{
                        minHeight: 'content-fit',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center'
                    }}><CircularProgress /></div>
                    :
                    <>
                      <div className="col-6">
                        <div className="top-tab">
                          <span className="title">Channel Level Constraints</span>
                          <Button onClick={() => {handleDownloadSchema('channel')}} variant="text">
                            {" "}
                            <DownloadSchemaIcon className="me-2" /> Download Schema
                          </Button>
                        </div>
                        <div>
                          <input
                            hidden
                            type="file"
                            accept=".csv"
                            ref={fileInputRefs[0]}
                            onChange={handleFileChange(0)}
                          />
                          <button
                            className="file-btn"
                            onClick={() => fileInputRefs[0].current?.click()}
                          >
                            <UploadIcon /> Upload File
                          </button>
                        </div>
                        <table>
                          <thead>
                            <tr>
                              {channelLevelConstraint?.header
                                ? channelLevelConstraint.header.length > 0 &&
                                channelLevelConstraint.header.map((header: string) => (
                                  <th>{header}</th>
                                ))
                                : null}
                            </tr>
                          </thead>
                          <tbody>
                            {channelLevelConstraint?.fileData.map((data: any) => (
                              <tr>
                                <td>{data.channel}</td>
                                <td>{data.min_budget}</td>
                                <td>{data.max_budget}</td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                      {
                        constraintsLoader ? 
                          <div style={{
                            minHeight: 'content-fit',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center'
                          }}><CircularProgress /></div>
                        :
                          <div className="col-6">
                            <div className="top-tab">
                              <span className="title">Geo Level Constraints</span>
                              <Button onClick={() => {handleDownloadSchema('geo')}} variant="text">
                                {" "}
                                <DownloadSchemaIcon className="me-2" /> Download Schema
                              </Button>
                            </div>
                            <div>
                              <input
                                hidden
                                type="file"
                                accept=".csv"
                                ref={fileInputRefs[1]}
                                onChange={handleFileChange(1)}
                              />
                              <button
                                className="file-btn"
                                onClick={() => fileInputRefs[1].current?.click()}
                              >
                                <UploadIcon /> Upload File
                              </button>
                            </div>
                            <table>
                              <thead>
                                <tr>
                                  {geoLevelConstraint?.header
                                    ? geoLevelConstraint.header.length > 0 &&
                                    geoLevelConstraint.header.map((header: string) => (
                                      <th>{header}</th>
                                    ))
                                    : null}
                                </tr>
                              </thead>
                              <tbody>
                                {geoLevelConstraint?.fileData.map((data: any) => (
                                  <tr>
                                    <td>{data.geo}</td>
                                    <td>{data.min_budget}</td>
                                    <td>{data.max_budget}</td>
                                  </tr>
                                ))}
                              </tbody>
                            </table>
                          </div>  
  
                      }
                    </>
                  }
                  </div>
              ) : null}
              {sectionName !== "maximum attainment" &&
                radioValue !== "" &&
                radioValue === "granular" ? (

                    constraintsLoader ? 
                    <div style={{
                      minHeight: 'content-fit',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center'
                      }}><CircularProgress /></div>
                    :
                    <div>
                      <div className="top-tab">
                        <span className="title">Geo - Channel Level Constraints*</span>
                        <Button onClick={() => {handleDownloadSchema('granular')}} variant="text">
                          {" "}
                          <DownloadSchemaIcon className="me-2" /> Download Schema
                        </Button>
                      </div>
                      <div className="mb-3">
                        <input
                          hidden
                          type="file"
                          accept=".csv"
                          ref={fileInputRefs[2]}
                          onChange={handleFileChange(2)}
                        />
                        <button
                          className="file-btn"
                          onClick={() => fileInputRefs[2].current?.click()}
                        >
                          <UploadIcon /> Upload File
                        </button>
                      </div>
                      <ul className="list-unstyled">
                        <>
                        {granularConstraint?.data?.constraint &&
                          granularConstraint?.data.constraint.length > 0
                          ? granularConstraint?.data.constraint.map((constraint: any) => (
                            <li>
                              {constraint.geoName}{" "}
                              <span>
                                <button
                                  onClick={() => OpenConstraintPopup(constraint)}
                                >
                                  +
                                </button>
                              </span>
                            </li>
                          ))
                          : null}
                        </>
                      </ul>
                      {CustomDialog}
                    </div>                     
                  
              ) : null}
              {sectionName === "maximum attainment" ? (
                <>
                  <div className="mb-3">
                    <h6>Maximize channel spends at overall level</h6>
                  </div>
                  <div className="row">
                    {maximumAtt?.map((data: any, index: number) => (
                      <div className="col-6">
                        <div className="top-tab">
                          <span className="title">{data.title}</span>
                        </div>
                        <div>
                          <FormGroup className="flex-row custom-check">
                            {data?.options.map((option: any) => (
                              <FormControlLabel
                                control={<Checkbox checked={option.checked} onChange={(e) => handleCheckboxChange(e, data.title, option,index)}/>}
                                label={option?.title}
                                className="mx-0 mb-2 col-6"
                              />
                            ))}
                          </FormGroup>
                        </div>
                      </div>
                    ))}
                  </div>
                </>
              ) : null}
              <div className="text-end mt-3">
                <Button disabled={handleDisableSubmitButton()} className="custom-button" onClick={() => onRunClick(maximumAttSecondSec)}>Validate</Button>
              </div>
              {RunPopup}
            </div>
          </>
        :
          <div className="page-loader"><CircularProgress /></div>
      }
        <>{console.log(formLoader)}</>
      {/* </form> */}
    </div>
  );
};

export default CustomForm;