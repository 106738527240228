import React from "react";
import "./load-data.scss";
import { FileUpload } from "../file-upload/file-upload";
import { Services } from "../../../shared/services";
import { useAuth } from "../../../../utils/auth/auth.guard";
import { useNavigate } from "react-router-dom";
import { TableEvents } from "../../../../utils/enums/enum";
import { ApiEndPoints } from "../../../../utils/enums/apiendpoints";

import CustomTable from "../customTable/custome-table";
import { HISTORIC_DATA, LOAD_DATA_RECENT } from "../../../../assets/data/json";
import { ITableApiResponseBody } from "../../../../utils/interfaces/interface";
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import { useTrainer } from "../../services/trainer.service";
import { CircularProgress } from "@mui/material";


export function LoadData() {
  const AppService = React.useContext(Services.AppService);
  const auth = useAuth()!;
  const navigate = useNavigate();
  const trainer = useTrainer();

  const [recentUploadData, setRecentUploadData] = React.useState<ITableApiResponseBody>()
  const [historicUploadData, setHistoricUploadData] = React.useState<ITableApiResponseBody>()
  const [displayLoader, setDisplayLoader] = React.useState<boolean>(false)
  const [value, setValue] = React.useState('tab1');
  const [newDataset, setNewDataset] = React.useState(null);


  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue);
    if (newValue === 'tab1') { RecentUploadData() }
    if (newValue === 'tab2') { HistoricUploadData() }
  };

  const handleTableRowAction = (action: string, row: any) => {
    // Handle the emitted event here
    switch(action) {
      case 'set_dataset': 
      // console.log(row)
      SetDatasetName(row.dataset_id);
      break;
      case 'delete':
        DeleteDataset(row.dataset_id)
        break;
    }
  };

  const RecentUploadData = async () => {
    const id = newDataset ? newDataset : trainer.latestDataset
    // console.log(id)
    if (id) {
      setDisplayLoader(true)
      AppService.API_HANDLER({
        auth,
        requestMethod: "get",
        url: ApiEndPoints.RECENT_UPLOAD.toString() + id,
        useGlobalLoader: false,
        showErrorMessage: true,
        showSuccessMessage: false,
        fetchFromApi: true,
        LOCAL_RESPONSE: LOAD_DATA_RECENT,
        successCb: (res: any) => {
          if (res?.data?.table_headers) {
            const len = res.data.table_headers.length;
            const extraColumn = {
              id: len + 1,
              name: "",
              key: "action",
              hidden: false,
              isSort: false,
              type: "action",
            };
            res.data.table_headers.push(extraColumn);
          }
          HistoricUploadData()
          setRecentUploadData(res);
          setDisplayLoader(false)
          
        },
        failedCb: (res: any) => {
          setDisplayLoader(false)
        },
      });
    }
  };

  const HistoricUploadData = async () => {
    setDisplayLoader(true)
    AppService.API_HANDLER({
      auth,
      requestMethod: "get",
      url: ApiEndPoints.HISTORIC_UPLOAD.toString(),
      useGlobalLoader: true,
      showErrorMessage: true,
      showSuccessMessage: false,
      fetchFromApi: true,
      LOCAL_RESPONSE: HISTORIC_DATA,
      successCb: (res: any) => {
        if (res?.data?.table_headers) {
          const len = res.data.table_headers.length;
          const extraColumn = {
            id: len + 1,
            name: "",
            key: "action",
            hidden: false,
            isSort: false,
            type: "action",
          };
          res.data.table_headers.push(extraColumn);
        }
        let temp: any = {
          label: "",
          key: "dataset_name",
          options: []
        }
        res.data.table_data.forEach((d: any) => {
          temp.options.push({
            title: d.dataset_name,
            value: d.dataset_id,
          })
        })
        trainer.savedDatasets?.forEach((d: any) => {
          temp.options.push({
            title: d.dataset_name,
            value: d.dataset_id,
          })
        })
        // console.log(temp)
        // console.log([temp], res)
        trainer.setSavedDatasets([temp]);
        setHistoricUploadData(res);
        setDisplayLoader(false)
      },
      failedCb: (res: any) => {
        setDisplayLoader(false)
      },
    });
  };

  const SetDatasetName = async (dataset_name: string) => {
    trainer.setDatasetName(dataset_name);
    // setTimeout(() => navigate("/dashboard"), 100);
    navigate("/dashboard")


    // AppService.API_HANDLER({
    //   auth,
    //   requestMethod: "post",
    //   url: ApiEndPoints.SET_DATASET.toString() + dataset_name,
    //   useGlobalLoader: true,
    //   showErrorMessage: true,
    //   showSuccessMessage: false,
    //   fetchFromApi: true,
    //   LOCAL_RESPONSE: {
    //     message: "Selected the data all_test_data_3 from output_data folder'",
    //   },
    //   successCb: (res: any) => {
    //     trainer.setDatasetName(dataset_name);
    //     trainer.setProcessId(res.process_id)
    //     setTimeout(() => navigate("/dashboard"), 100);
    //   },
    //   failedCb: (res: any) => {},
    // });
  };

  const DeleteDataset = async (dataset_name: string) => {
    setDisplayLoader(true)
    AppService.API_HANDLER({
      auth,
      requestMethod: "post",
      url: ApiEndPoints.DELETE_DATASET.toString() + dataset_name,
      useGlobalLoader: true,
      showErrorMessage: true,
      showSuccessMessage: false,
      fetchFromApi: true,
      LOCAL_RESPONSE: {
        message: "Dataset" + dataset_name + "has been deleted successfully",
      },
      successCb: (res: any) => {
        setDisplayLoader(false)
        if(value === 'tab1') {
          RecentUploadData();
        }
        else {
          HistoricUploadData();
        }
      },
      failedCb: (res: any) => {
        setDisplayLoader(false)
      },
    });
  };

  // React.useEffect(() => {
  //   RecentUploadData();
  // }, []);

  React.useEffect(() => {
    if(trainer.saveSuccess) {
      RecentUploadData();
    }
    trainer.setSaveSuccess(false);
  }, [trainer.saveSuccess])

  return (
    <>
      <section className="loadData-page">
        <div className="row g-0 h-100">
          <div className="col-4 left-panel">
            <h5>Upload data</h5>
            <div>
              <FileUpload setNewDataset={setNewDataset} setPageLoader={setDisplayLoader} />
            </div>
          </div>
          <div className="col-8">
            <div className="custom-tab">
              <TabContext value={value}>
                <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                  <TabList onChange={handleChange}>
                    <Tab className="text-capitalize current-tab" label="Currently Upload" value="tab1" />
                    <Tab className="text-capitalize historical-tab" label="Historical Upload" value="tab2" />
                  </TabList>
                </Box>
                <TabPanel value="tab1">
                  {" "}
                  {
                    displayLoader ? 
                      <div className="page-loader">
                        <CircularProgress />
                      </div>
                    :
                    recentUploadData ? (
                      <CustomTable
                        data={recentUploadData}
                        setDisplayLoader={setDisplayLoader}
                        onTableRowAction={(action, row) =>
                          handleTableRowAction(action, row)
                        }
                      />
                    ) : 
                      <div className="w-100 h-100 d-flex justify-content-center align-items-center">
                        No Current Dataset Uploaded
                      </div>
                    }
                </TabPanel>
                <TabPanel value="tab2">
                  {
                    displayLoader ? 
                      <div className="page-loader">
                        <CircularProgress />
                      </div>
                    :
                    historicUploadData ? (
                      <CustomTable
                        data={historicUploadData}
                        setDisplayLoader={setDisplayLoader}
                        onTableRowAction={(action, row) =>
                          handleTableRowAction(action, row)
                        }
                      />
                    ) : 
                      <div className="w-100 h-100 d-flex justify-content-center align-items-center">
                        No Datasets Available
                      </div>
                    }
                </TabPanel>
              </TabContext>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
