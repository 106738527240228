import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  CircularProgress,
} from "@mui/material";
import { CloseIcon } from "../../../../assets/icons/Icons";
import { BarChart } from "@mui/x-charts";
import { useState } from "react";

export const CompareScenario = (props: any) => {
  const handelClick = () => {
    props.handleCompareScenarioClose();
    props.generateReportClick("click on generate report");
  };

  return (
    <>
      <Dialog open={props.isOpen} onClose={props.handleCompareScenarioClose} className="scenarios-dialog">
        <div className="dialog-top">
          <DialogTitle className="p-0">Compare Scenarios</DialogTitle>
          <Button onClick={props.handleCompareScenarioClose} className="p-0">
            <CloseIcon />
          </Button>
        </div>
        <DialogContent>
          {props?.data ? (
            <>
              <div className="row g-0">
                <div className="col-3">
                  {props?.data?.table_headers?.length > 0
                    ? props?.data?.table_headers.map((header: any) => (
                        <div key={header.name}>
                          <span>{header.name}</span>
                        </div>
                      ))
                    : null}
                </div>
                {props?.data?.table_data?.map((record: any) => (
                  <div className="col-3" key={record.experiment_name}>
                    <>
                      <div className="name">
                        <span>{record?.experiment_name}</span>
                      </div>
                      <div>
                        <span>{record?.total_spend}</span>
                      </div>
                      <div>
                        <span>{record?.incremental_sales}</span>
                      </div>
                      <div>
                        <BarChart
                          xAxis={[
                            {
                              id: "xAxis",
                              scaleType: "band",
                              data: record?.roi?.chartData.map(
                                (data: any) => data.key
                              ),
                            },
                          ]}
                          yAxis={[
                            {
                              id: "leftAxis",
                              scaleType: "linear",
                              min: record?.roi?.metaData[0].scale.y1axis
                                .minValue,
                              max: record?.roi?.metaData[0].scale.y1axis
                                .maxValue,
                              labelStyle: { fontSize: 12 },
                            },
                          ]}
                          series={[
                            {
                              data: record?.roi?.chartData.map(
                                (key: any) => key.bar1
                              ),
                              color: "#29628B"
                            },
                          ]}
                          leftAxis="leftAxis"
                          bottomAxis={{
                            axisId: "xAxis",
                            tickLabelStyle: {
                              angle: 40,
                              textAnchor: 'start',
                              fontSize: 10,
                              fontWeight: 500
                            },
                          }}
                          margin={{
                            left: 25,
                            right: 10,
                            top: 10,
                            bottom: 30,
                          }}
                          width={250}
                          height={200}
                        />
                      </div>
                      <div>
                        <BarChart
                          xAxis={[
                            {
                              id: "xAxis",
                              scaleType: "band",
                              data: record?.mroi?.chartData.map(
                                (data: any) => data.key
                              ),
                            },
                          ]}
                          yAxis={[
                            {
                              id: "leftAxis",
                              scaleType: "linear",
                              min: record?.mroi?.metaData[0].scale.y1axis
                                .minValue,
                              max: record?.mroi?.metaData[0].scale.y1axis
                                .maxValue,
                              labelStyle: { fontSize: 12 },
                            },
                          ]}
                          series={[
                            {
                              data: record?.mroi?.chartData.map(
                                (key: any) => key.bar1
                              ),
                              color: "#29628B"
                            },
                          ]}
                          leftAxis="leftAxis"
                          width={250}
                          height={200}
                          bottomAxis={{
                            axisId: "xAxis",
                            tickLabelStyle: {
                              angle: 40,
                              textAnchor: 'start',
                              fontSize: 10,
                              fontWeight: 500
                            },
                          }}
                          margin={{
                            left: 25,
                            right: 10,
                            top: 10,
                            bottom: 30,
                          }}
                        />
                      </div>
                    </>
                  </div>
                ))}
              </div>
              <div className="text-center mt-3">
                <Button onClick={handelClick} className="btn-secondary custom-button">Generate Report</Button>
              </div>
            </>
          ) : (
            <div className="page-loader">
              <CircularProgress />
            </div>
          )}
        </DialogContent>
      </Dialog>
    </>
  );
};
