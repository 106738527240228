export const data = {
  status: "success",
  message: "successfully fetch the data.",
  data: {
    table_headers: [
      {
        id: 1,
        name: "Experiment Name",
        key: "experiment_name",
      },
      {
        id: 2,
        name: "Total Spend",
        key: "total_spend",
      },
      {
        id: 3,
        name: "Incremental Sales",
        key: "incremental_sales",
      },
      {
        id: 4,
        name: "ROIs",
        key: "roi",
      },
      {
        id: 5,
        name: "mROIs",
        key: "mroi",
      },
    ],
    table_data: [
      {
        experiment_name: "2024_Q1_India",
        total_spend: "100 CR",
        incremental_sales: "2600 CR",
        roi: {
            metaData: [
                {
                    "visualType": {
                        "type": "vertical_group_bar_chart"
                    },
                    "legends": {},
                    "scale":{
                        "xaxis": {
                           "type":"band"
                        },
                        "y1axis": {
                            "minValue":0,
                            "maxValue": 25,
                            "label": "Spend",
                            "type": "linear"
                        }
                    }
                }
            ],
            chartData:[
                {
                    "key": "TV",
                    "bar1": 9
                },
                {
                    "key": "OHH",
                    "bar1": 7,

                },
                {
                    "key": "Google",
                    "bar1": 13
                },
                {
                    "key": "Meta",
                    "bar1": 14
                },
                {
                    "key": "OTT",
                    "bar1": 10,
                },
                {
                    "key": "Print",
                    "bar1": 19
                },
                {
                    "key": "Digital",
                    "bar1": 4
                }  
            ]
        },
        mroi: {
            metaData: [
                {
                    "visualType": {
                        "type": "vertical_group_bar_chart"
                    },
                    "legends": {},
                    "scale":{
                        "xaxis": {
                           "type":"band"
                        },
                        "y1axis": {
                            "minValue":0,
                            "maxValue": 25,
                            "label": "Spend",
                            "type": "linear"
                        }
                    }
                }
            ],
            chartData:[
                {
                    "key": "TV",
                    "bar1": 9
                },
                {
                    "key": "OHH",
                    "bar1": 7,

                },
                {
                    "key": "Google",
                    "bar1": 13
                },
                {
                    "key": "Meta",
                    "bar1": 14
                },
                {
                    "key": "OTT",
                    "bar1": 10,
                },
                {
                    "key": "Print",
                    "bar1": 19
                },
                {
                    "key": "Digital",
                    "bar1": 4
                }
                
            ]
        }
      },
      {
        experiment_name: "2024_Q2_India",
        total_spend: "100 CR",
        incremental_sales: "2600 CR",
        roi: {
            metaData: [
                {
                    "visualType": {
                        "type": "vertical_group_bar_chart"
                    },
                    "legends": {},
                    "scale":{
                        "xaxis": {
                           "type":"band"
                        },
                        "y1axis": {
                            "minValue":0,
                            "maxValue": 25,
                            "label": "Spend",
                            "type": "linear"
                        }
                    }
                }
            ],
            chartData:[
                {
                    "key": "TV",
                    "bar1": 9,
                },
                {
                    "key": "OHH",
                    "bar1": 7,

                },
                {
                    "key": "Google",
                    "bar1": 13
                },
                {
                    "key": "Meta",
                    "bar1": 14,
                },
                {
                    "key": "OTT",
                    "bar1": 10,
                },
                {
                    "key": "Print",
                    "bar1": 19,
                },
                {
                    "key": "Digital",
                    "bar1": 4
                }  
            ]
        },
        mroi: {
            metaData: [
                {
                    "visualType": {
                        "type": "vertical_group_bar_chart"
                    },
                    "legends": {},
                    "scale":{
                        "xaxis": {
                           "type":"band"
                        },
                        "y1axis": {
                            "minValue":0,
                            "maxValue": 25,
                            "label": "Spend",
                            "type": "linear"
                        }
                    }
                }
            ],
            chartData:[
                {
                    "key": "TV",
                    "bar1": 9,
                },
                {
                    "key": "OHH",
                    "bar1": 7
                },
                {
                    "key": "Google",
                    "bar1": 13
                },
                {
                    "key": "Meta",
                    "bar1": 14,
                },
                {
                    "key": "OTT",
                    "bar1": 10
                },
                {
                    "key": "Print",
                    "bar1": 19,
                },
                {
                    "key": "Digital",
                    "bar1": 4
                }
                
            ]
        }
      },
      {
        experiment_name: "2024_Q3_India",
        total_spend: "100 CR",
        incremental_sales: "2600 CR",
        roi: {
            metaData: [
                {
                    "visualType": {
                        "type": "vertical_group_bar_chart"
                    },
                    "legends": {},
                    "scale":{
                        "xaxis": {
                           "type":"band"
                        },
                        "y1axis": {
                            "minValue":0,
                            "maxValue": 25,
                            "label": "Spend",
                            "type": "linear"
                        },
                        "y2axis": {
                            "minValue":0,
                            "maxValue": 100,
                            "label": "Incremental Sales",
                            "type": "linear"
                        }
                        
                    }
                }
            ],
            chartData:[
                {
                    "key": "TV",
                    "bar1": 9,
                },
                {
                    "key": "OHH",
                    "bar1": 7,

                },
                {
                    "key": "Google",
                    "bar1": 13
                },
                {
                    "key": "Meta",
                    "bar1": 14,
                },
                {
                    "key": "OTT",
                    "bar1": 10
                },
                {
                    "key": "Print",
                    "bar1": 19,
                },
                {
                    "key": "Digital",
                    "bar1": 4
                }  
            ]
        },
        mroi: {
            metaData: [
                {
                    "visualType": {
                        "type": "vertical_group_bar_chart"
                    },
                    "legends": {},
                    "scale":{
                        "xaxis": {
                           "type":"band"
                        },
                        "y1axis": {
                            "minValue":0,
                            "maxValue": 25,
                            "label": "Spend",
                            "type": "linear"
                        }
                    }
                }
            ],
            chartData:[
                {
                    "key": "TV",
                    "bar1": 9
                },
                {
                    "key": "OHH",
                    "bar1": 7,

                },
                {
                    "key": "Google",
                    "bar1": 13
                },
                {
                    "key": "Meta",
                    "bar1": 14,
                },
                {
                    "key": "OTT",
                    "bar1": 10,
                },
                {
                    "key": "Print",
                    "bar1": 19,
                },
                {
                    "key": "Digital",
                    "bar1": 4
                }
                
            ]
        }
      },
    ],
  },
};
