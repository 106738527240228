export const data = {
  status: "200",
  message: "successfully featch the data.",
  data: {
    table_headers: [
      {
        id: 1,
        name: "Channel",
        key: "channelName",
        hidden: false,
        isSort: true,
      },
      {
        id: 2,
        name: "Spend",
        key: "spend",
        hidden: false,
        isSort: true,
      },
      {
        id: 3,
        name: "Incremental Sales",
        key: "incrementalSales",
        hidden: false,
        isSort: true,
      },
    ],
    table_data: [
      {
        channelName: "TV",
        spend: 9,
        incrementalSales: 5,
      },
      {
        channelName: "OHH",
        spend: 7,
        incrementalSales: 4,
      },
      {
        channelName: "Google",
        spend: 13,
        incrementalSales: 6,
      },
      {
        channelName: "Meta",
        spend: 14,
        incrementalSales: 8,
      },
      {
        channelName: "OTT",
        spend: 10,
        incrementalSales: 70,
      },
      {
        channelName: "Print",
        spend: 19,
        incrementalSales: 3,
      },
      {
        channelName: "Digital",
        spend: 4,
        incrementalSales: 3,
      },
    ],
  },
};
