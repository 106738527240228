import { ApiStatus } from "../../../../utils/enums/enum";
import { ITableApiResponseBody } from "../../../../utils/interfaces/interface";

export const data: ITableApiResponseBody = {
    "status": ApiStatus.Success,
	"message": "Model catalog data is fetch successfully.",
    "data": {
        "table_headers": [
            {
                "id": 1,
                "name": "Dataset Name",
                "key": "dataset_name",
                "hidden": false,
                "isSort": true,
                "type": "text_checkbox"
            },
            {
                "id": 2,
                "name": "Created on",
                "key": "created_date",
                "hidden": false,
                "isSort": true,
                "type": ""
            }
        ],
        "table_data": [
            {
                "dataset_name": "India_2023_Q1_All_Stores",
                "created_date": "10 Dec 2022 13:45"

            },
            {
                "dataset_name": "India_2023_Q2_New_Stores",
                "created_date": "10 Dec 2022 07:55"

            },
            {
                "dataset_name": "India_2023_Q4_Online",
                "created_date": "9 Dec 2022 13:45"

            },
            {
                "dataset_name": "India_2023_Q2_New_Stores",
                "created_date": "1 Dec 2022 07:55"

            }
        ],
        "total_records": 16
    }
}