import React from "react";
import { useState } from "react";
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  FormControl,
  Select,
  MenuItem,
  Menu,
  CircularProgress,
} from "@mui/material";
import { CloseIcon,DownloadSchemaIcon, PDF, WORD } from "../../../../assets/icons/Icons";

export const CompareReport = (props: any) => {
  const [docType, setDocType] = useState("");
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleTypeChange = (value: any) => {
    setDocType(value);
  //  console.log(value);
  };

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleMenuItemClick = (type: string) => {
    // Handle the selected document type here
  //  console.log(`Selected document type: ${type}`);
    setAnchorEl(null);
  };

  return (
    <>
      <Dialog open={props.isOpen} onClose={props.handleClose} className="report-popup">
        <div className="dialog-top">
          <DialogTitle className="p-0">Report</DialogTitle>
          <Button onClick={props.handleClose} className="p-0">
            <CloseIcon />
          </Button>
        </div>
        <DialogContent>
          {props?.data?.report ? (
            <div>
              <div className="report-content">
                <p>{props?.data?.report}</p>
              </div>
              <div className="text-center mt-3">
                <FormControl>
                  <Button onClick={handleClick} className="btn-secondary custom-button"><DownloadSchemaIcon className="me-2" /> Download Report</Button>
                  <Menu
                    anchorEl={anchorEl}
                    open={Boolean(anchorEl)}
                    onClose={handleClose}
                    anchorOrigin={{
                      vertical: "bottom",
                      horizontal: "right",
                    }}
                    transformOrigin={{
                      vertical: "top",
                      horizontal: "right",
                    }}
                  >
                    <MenuItem onClick={() => handleMenuItemClick("PDF")}>
                      <PDF className="me-2" />PDF <span className="ms-4">.pdf</span>
                    </MenuItem>
                    <MenuItem onClick={() => handleMenuItemClick("Word")}>
                      <WORD className="me-2" />Word <span className="ms-4">.word</span>
                    </MenuItem>
                  </Menu>
                </FormControl>
              </div>
            </div>
          ) : (
            <div className="page-loader">
              <CircularProgress />
            </div>
          )}
        </DialogContent>
      </Dialog>
    </>
  );
};
