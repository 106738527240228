import React from "react";

import { useState, useEffect } from "react";
import { ITableApiResponseBody } from "../../../../utils/interfaces/interface";

import {
  FormControl,
  Input,
  InputLabel,
  TextField,
  Select,
  MenuItem,
  Modal,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
} from "@mui/material";
import CustomTable from "../customTable/custome-table";
import { CloseIcon, CompareArrow } from "../../../../assets/icons/Icons";

export const CompareResult = (props: any) => {
  const [experimentType, setExperimentType] = useState("fixedBudget");
  const [filteredData, setFilteredData] = React.useState<
    ITableApiResponseBody | undefined
  >();
  const [selectedMultiCheckboxes, setSelectedMultiCheckboxes] = useState<string[]>([]);


  const exp_table_headers = [
    {
      id: 1,
      name: "Scenario",
      key: "scenario",
      hidden: false,
      isSort: true,
      type: "text_multi_checkbox",
    },
    {
      id: 2,
      name: "Incremental Sales",
      key: "incrementalSales",
      hidden: false,
      isSort: true,
    },
    {
      id: 3,
      name: "Spends",
      key: "spends",
      hidden: false,
      isSort: true,
    },
  ];

  const handleNext = () => {
    props.handleClose();
    props.compareClick(selectedMultiCheckboxes);
    setTimeout(()=> {setSelectedMultiCheckboxes([])}, 100);
  };

  const filterTableData = () => {
    if (props.tableData) {
      const filteredRows = props.tableData.data.table_data.filter((row: any) =>
        row.type.toLowerCase().includes(experimentType.toLowerCase())
      );
      setFilteredData({
        ...props.tableData,
        data: {
          ...props.tableData.data,
          table_headers: exp_table_headers,
          table_data: filteredRows,
        },
      });
    }
  };

  useEffect(() => {
     if (props.isOpen) {
        filterTableData();
     }
  }, [experimentType, props.tableData]); // Add props.tableData as a dependency

  useEffect(() => {
    // Perform initial filtering when the component mounts
    if (props.isOpen) {
        filterTableData();
    }
  }, []); // Empty dependency array for initial mount

  const handleTypeChange = (value: any) => {
    setExperimentType(value);
    filterTableData();
  };

  const handleTableRowAction = (action: string, row: any) => {
    // Handle the emitted event here
  //  console.log(`Received event: ${action} for row:`, row);
    switch(action) {
      case "click":
      //  console.log("*****");
        if (selectedMultiCheckboxes.includes(row)) {
          // If checkbox is already checked, remove it from the selection
          setSelectedMultiCheckboxes((prevSelected) =>
            prevSelected.filter((item) => item !== row)
          );
        } else {
          // Check if the limit of 3 checkboxes is reached
          if (selectedMultiCheckboxes.length < 3) {
    
            // If not, add the checkbox to the selection
            setSelectedMultiCheckboxes((prevSelected) => [...prevSelected, row]);
          } else {
    
            // If the limit is reached, show a warning message (you can customize this part)
            alert("You can only select up to 3 scenarios.");
          //  console.log("limit reach")
          }
        }
        break;
    }
  };

  return (
    <>
      <Dialog open={props.isOpen} onClose={props.handleClose} className="experiments-popup">
        <div className="dialog-top">
          <DialogTitle className="p-0">Compare experiments</DialogTitle>
          <Button onClick={props.handleClose} className="p-0">
            <CloseIcon />
          </Button>
        </div>
        <DialogContent>
          <div>
            <>
              <FormControl className="d-flex flex-row align-items-center mb-3">
                <label className="me-2">Scenario</label>
                <Select
                  labelId="compare-simple-select-label"
                  id="compare-simple-select"
                  value={experimentType}
                  onChange={(event) => {
                    handleTypeChange(event.target.value);
                  }}
                  className="form-control"
                >
                  <MenuItem value="fixedBudget">Budget Optimization</MenuItem>
                  <MenuItem value="targetAttainment">
                    Target Attainment
                  </MenuItem>
                  <MenuItem value="maximumAttainment">
                    Maximum Attainment
                  </MenuItem>
                </Select>
              </FormControl>
              {filteredData !== undefined ? (
                <>
                  <div className="mb-2">
                    Select all the experiments you want to compare(at most 3)
                  </div>
                  <CustomTable
                    data={filteredData}
                    onTableRowAction={(action, row) =>
                      handleTableRowAction(action, row)
                    }
                  />
                </>
              ) : (
                <div className="noData-found">No Table Data</div>
              )}
              <div className="text-center mt-3">
                <Button onClick={handleNext} className="btn-secondary custom-button" disabled={selectedMultiCheckboxes.length < 3}> <CompareArrow className="me-2" />Compare</Button>
              </div>
            </>
          </div>
        </DialogContent>
      </Dialog>
    </>
  );
};
