export const data = {
  status: "200",
  message: "successfully fetch the data.",
  data: {
    table_headers: [
      {
        id: 1,
        name: "Scenario",
        key: "scenario",
        hidden: false,
        isSort: true,
      },
      {
        id: 2,
        name: "Experiment Name",
        key: "experimentName",
        hidden: false,
        isSort: true,
      },
      {
        id: 3,
        name: "Status",
        key: "status",
        hidden: false,
        isSort: true,
        type: 'status'
      },
      {
        id: 4,
        name: "Run On",
        key: "runOn",
        hidden: false,
        isSort: true,
      },
      {
        id: 5,
        name: "Incremental Sales",
        key: "incrementalSales",
        hidden: false,
        isSort: true,
      },
      {
        id: 6,
        name: "Spends",
        key: "spends",
        hidden: false,
        isSort: true,
      }
    ],
    table_data: [
      {
        experimentId: 1,
        scenario: "Budget Optimization",
        experimentName: "2023_India_150_Cr",
        status: "Completed",
        runOn: "10 Dec 2022",
        incrementalSales: "4050 Cr",
        spends: "250 Cr",
        type: "fixedBudget",
        totalSales: "1023 Cr",
        information: [
          "Google is the highest spending channel due to its high profitability",
          "OOH spends have been increased due to the mROI",
          "OTT spends have been reduced because of low ROI"
        ]
      },
      {
        experimentId: 2,
        scenario: "Budget Optimization",
        experimentName: "2021_India",
        status: "Completed",
        runOn: "10 Dec 2022",
        incrementalSales: "4050 Cr",
        spends: "250 Cr",
        type: "fixedBudget",
        totalSales: "1023 Cr",
        information: [
          "Google is the highest spending channel due to its high profitability",
          "OOH spends have been increased due to the mROI",
          "OTT spends have been reduced because of low ROI"
        ]
      },
      {
        experimentId: 3,
        scenario: "Target Attainment",
        experimentName: "2021 Budget 2",
        status: "Failed",
        runOn: "10 Dec 2022",
        incrementalSales: "4050 Cr",
        spends: "250 Cr",
        type: "targetAttainment",
        totalSales: "1023 Cr",
        information: [
          "Google is the highest spending channel due to its high profitability",
          "OOH spends have been increased due to the mROI",
          "OTT spends have been reduced because of low ROI"
        ]
      },
      {
        experimentId: 4,
        scenario: "Target Attainment",
        experimentName: "2020 Past",
        status: "In Progress",
        runOn: "10 Dec 2022",
        incrementalSales: "4050 Cr",
        spends: "100 Cr",
        type: "targetAttainment",
        totalSales: "1023 Cr",
        information: [
          "Google is the highest spending channel due to its high profitability",
          "OOH spends have been increased due to the mROI",
          "OTT spends have been reduced because of low ROI"
        ]
      },
      {
        experimentId: 5,
        scenario: "Maximum Attainment",
        experimentName: "Revenue",
        status: "Failed",
        runOn: "10 Dec 2022",
        incrementalSales: "4050 Cr",
        spends: "250 Cr",
        type: "maximumAttainment",
        totalSales: "1023 Cr",
        information: [
          "Google is the highest spending channel due to its high profitability",
          "OOH spends have been increased due to the mROI",
          "OTT spends have been reduced because of low ROI"
        ]
      },
      {
        experimentId: 6,
        scenario: "Budget Optimization",
        experimentName: "2023_India_TV_Google_100Cr",
        status: "Failed",
        runOn: "10 Dec 2022",
        incrementalSales: "4050 Cr",
        spends: "250 Cr",
        type: "fixedBudget",
        totalSales: "1023 Cr",
        information: [
          "Google is the highest spending channel due to its high profitability",
          "OOH spends have been increased due to the mROI",
          "OTT spends have been reduced because of low ROI"
        ]
      },
      {
        experimentId: 7,
        scenario: "Maximum Attainment",
        experimentName: "2023_Europe_Jul-Oct",
        status: "Failed",
        runOn: "10 Dec 2022",
        incrementalSales: "4050 Cr",
        spends: "250 Cr",
        type: "maximumAttainment",
        totalSales: "1023 Cr",
        information: [
          "Google is the highest spending channel due to its high profitability",
          "OOH spends have been increased due to the mROI",
          "OTT spends have been reduced because of low ROI"
        ]
      },
      {
        experimentId: 8,
        scenario: "Budget Optimization",
        experimentName: "2023_Malaysia_50K",
        status: "Failed",
        runOn: "10 Dec 2022",
        incrementalSales: "4050 Cr",
        spends: "250 Cr",
        type: "fixedBudget",
        totalSales: "1023 Cr",
        information: [
          "Google is the highest spending channel due to its high profitability",
          "OOH spends have been increased due to the mROI",
          "OTT spends have been reduced because of low ROI"
        ]
      }
    ],
  },
};
