import RouterModule from "../../utils/auth/router.module";
import { Navigate } from "react-router-dom";
import Protected from "../../utils/auth/route.guard";
import { LoadData } from "./components/load-data/load-data";
import LandingPage from "./pages/landing-page/landing-page";
import Dashboard from "./pages/dashboard/dashboard";
import Optimize from "./pages/optimize/optimize";
import Result from "./pages/result/result";
import ResultPage from "./pages/result-output/result-output";
import LandingDashboard from "./components/landing-dashboard/landing-dashboard";

export const routes = () => {
  return (
    <Protected>
      <RouterModule
        routes={[
          {
            path: "",
            element: <LandingPage />,
            children: [
              {
                path: "landing-dashboard",
                element: <LandingDashboard />,
              },
              {
                path: "load-data",
                element: <LoadData />,
              },
              {
                path: "dashboard",
                element: <Dashboard />,
              },
              {
                path: "optimize",
                element: <Optimize />,
              },
              {
                path: "result",
                element: <Result />,
              },
              {
                path: "result/:experimentId",
                element: <ResultPage />,
              },

              {
                path: "*",
                element: <Navigate to={`/landing-dashboard`} />,
              },
            ],
          },
        ]}
      />
    </Protected>
  );
};
