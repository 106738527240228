import React from 'react';
import { Navigate } from 'react-router-dom';
import { LoginModuleRoutes } from './modules/login';
import { Dashboard, MarketingMixModuleRoutes, Optimize, Result } from './modules/marketing-mix';
import RouterModule from './utils/auth/router.module';
import { AppRoutes } from './utils/enums/enum';

export const AppModuleRoutes = () => {
	return <RouterModule routes={
		[
			{
				path: "/",
				element: <Navigate to={`/${AppRoutes.LandingDashboard}`} />
			},
			{
				path: `/${AppRoutes.Login}/*`,
				element: <LoginModuleRoutes /> // TODO: Login page can be used directly
			},
			{
				path: "/*",
				element: <MarketingMixModuleRoutes />
			},
			{
				path: "*",
				element: <Navigate to={`/${AppRoutes.LandingDashboard}`} />
			}
		]
	} />
}