import { Button } from "@mui/material";
import React from "react";
import {
  CompleteDataCheck,
  DataCheck,
  DownloadSchemaIcon,
  UploadIcon,
  DeleteIcon,
  FileIcon,
  DatasetComplete
} from "../../../../assets/icons/Icons";
import { Services } from "../../../shared/services";
import { useAuth } from "../../../../utils/auth/auth.guard";
import { useNavigate } from "react-router-dom";
import { IFileUploadApiRes } from "../../../../utils/interfaces/interface";
import { ApiEndPoints } from "../../../../utils/enums/apiendpoints";
import { response } from "express";
import { useTrainer } from "../../services/trainer.service";

export function FileUpload({setNewDataset, setPageLoader} : any) {
  const [currentStep, setCurrentStep] = React.useState(0);
  const [files, setFiles] = React.useState<Array<File | null>>([
    null,
    null,
    null,
    null,
    null,
    null,
    null
  ]);
  const [datasetName, setDatasetName] = React.useState<string>("");
  const [isNextButtonDisabled, setIsNextButtonDisabled] = React.useState([
    true,
    true,
    false,
    true,
    false,
    false,
    true
  ]);
  const [apiResponseMessage, setApiResponseMessage] = React.useState<Array<IFileUploadApiRes>>([]);
  const [mandatoryUpload, setMandatoryUpload] = React.useState(false);

  const [requiredFiles, setRequiredFiles] = React.useState<Array<string>>([
    "marketing_spend_data",
    "sales_data",
    "response_curve_data",
    "channel_fee_percentage"
  ]);

  const AppService = React.useContext(Services.AppService);
  const auth = useAuth()!;
  const trainer = useTrainer();


  const steps = [
    {
      name: "Marketing Spends",
      key: "marketing_spend",
      api: "",
      description: "Please upload Marketing Spends Data",
      buttonText: "Choose File",
      required: true,
    },
    {
      name: "Historical Sales",
      api: "",
      key: "historical_sales",
      description: "Please upload Sales data",
      buttonText: "Choose File",
      required: true,
    },
    {
      name: "Forecasted Sales",
      api: "",
      key: "forecasted_sales",
      description: "Please upload Forecasted Sales data",
      buttonText: "Choose File",
      required: false,
    },
    {
      name: "Response Curve",
      api: "",
      key: "response_curve",
      description: "Please upload Response Curve data",
      buttonText: "Choose File",
      required: true,
    },
    {
      name: "Adstock",
      api: "",
      key: "adstock_data",
      description: "Please upload Adstock Data",
      buttonText: "Choose File",
      required: false,
    },
    {
      name: "Events Calendar",
      api: "",
      key: "events_calendar",
      description: "Please upload Events Calendar data",
      buttonText: "Choose File",
      required: false,
    },
    {
      name: "Channel Fee Percentage",
      api: "",
      key: "channel_fee_percentage",
      description: "Please upload Channel Fee Percentage data",
      buttonText: "Choose File",
      required: true,
    },
    {
      name: "Set Dataset name",
      api: "",
      key: "dataset_name",
      description: "Please enter a name to save the dataset",
      buttonText: "",
      required: true,
    },
  ];
  const uploadData = async (event: any ,file: File, key:string, index:number) => {
    const formData = new FormData();
    formData.append('file', file);
    await AppService.uploadFileToS3(ApiEndPoints.UPLOAD_DATA + "/?filename="+key , file).then(async (res: any)=> {
      return {data: await res.json(), status: res.status}
    }).then((res: any) => {
      if (res.status === 403 || res.status === 401) {
        window.location.href = window.location.origin + "/login";
        // auth?.setSnackBarDetails({
        //   message: 'Cookies expired. Redirecting to login',
        //   type: "error",
        // });
      }
      if (res.status == 200) {
        //  console.log("upload data response",res?.data,file,key,index)
          // debugger;
        trainer.setUploadLoadData((prevVal: any) => ({...prevVal, [key]: res.data[key]}))
    
        const newIsNextButtonDisabled = [...isNextButtonDisabled];
        newIsNextButtonDisabled[index] = !file;
        const newRequiredFiles = requiredFiles.filter(fileKey => fileKey !== key);
        setRequiredFiles(newRequiredFiles);
        setIsNextButtonDisabled(newIsNextButtonDisabled);

        const newApiResponseMessage = [...(apiResponseMessage ?? [])];
        newApiResponseMessage[index] = {
          status: "success", // Set status property based on failure
          message: "File Uploaded Successfully",
        };
        setApiResponseMessage(newApiResponseMessage)
      } else {
        const newApiResponseMessage = [...(apiResponseMessage ?? [])];
        newApiResponseMessage[index] = {
          status: "failed", // Set status property based on failure
          message: "Error in file upload",
        };
        event.target.value = null
        setApiResponseMessage(newApiResponseMessage)
      }
    })
    .catch((err) => {
      const newApiResponseMessage = [...(apiResponseMessage ?? [])];
      newApiResponseMessage[index] = {
        status: "failed", // Set status property based on failure
        message: "Error in file upload",
      };
      event.target.value = null 
      setApiResponseMessage(newApiResponseMessage);
    });
  };

  const downloadSchema = async (key: string) => {
    try {
    AppService.downloadFile(ApiEndPoints.DOWNLOAD_SCHEMA.toString() + "/?filename=" + key).then(async response => {
      if (response.status === 403 || response.status === 401) {
        window.location.href = window.location.origin + "/login";
        // auth?.setSnackBarDetails({
        //   message: 'Session expired. Redirecting to login',
        //   type: "error",
        // });
      }
      if (response.status == 200) {
        // Extract filename from Content-Disposition header
        const contentDisposition = response.headers.get("content-disposition");
        const filenameMatch = contentDisposition && contentDisposition.match(/filename="(.+)"$/);
        const suggestedFileName = filenameMatch ? filenameMatch[1] : key + ".csv";
    
        // Create a Blob from the response data
        const blob =  response.blob();
    
        // Create a download link
        const url = URL.createObjectURL(await blob);
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", suggestedFileName);
        document.body.appendChild(link);
    
        // Trigger the download
        link.click();
    
        // Clean up
        document.body.removeChild(link);
        URL.revokeObjectURL(url);
      }
  
      
    })
  }
  catch (error) {
    console.error("Error downloading file:", error);
  } 
  };

  const SetDatasetName = async () => {
    setPageLoader(true)
    // console.log(trainer.uploadLoadData)
    AppService.API_HANDLER({
      auth,
      requestMethod: "post",
      url: ApiEndPoints.SAVE_DATASET.toString() + datasetName,
      payload: {...trainer.uploadLoadData, dataset_name: datasetName},
      useGlobalLoader: true,
      showErrorMessage: true,
      showSuccessMessage: false,
      fetchFromApi: true,
      LOCAL_RESPONSE: {
        message: "Selected the data all_test_data_3 from output_data folder'",
        dataset_id: 1
      },
      successCb: (res: any) => {
        // console.log(res)
        const newApiResponseMessage = [...(apiResponseMessage ?? [])];
        newApiResponseMessage[6] = {
          status: "success", // Set status property based on failure
          message: "Validation Successful!",
        };
        // const newApiResponseMessage: any = []
        setApiResponseMessage(newApiResponseMessage);
        trainer.setSaveSuccess(true)
        trainer.setLatestDataset(res.dataset_id)
        setNewDataset(res.dataset_id)
        // setPageLoader(false)
      },
      failedCb: (res: any) => {
        const newApiResponseMessage = [...(apiResponseMessage ?? [])];
        newApiResponseMessage[6] = {
          status: "success", // Set status property based on failure
          message: res.message || "Validation Failed",
        };
        setApiResponseMessage(newApiResponseMessage)
        trainer.setSaveSuccess(false)
        setPageLoader(false)
      },
    });
  };

  React.useEffect(() => {
    // Check if the status is success for specific indices
    const successIndices = [0, 1, 3];
    const isSuccess = successIndices.every(index => apiResponseMessage[index]?.status === 'success');

    // Update the mandatoryUpload state
    setMandatoryUpload(isSuccess);

  }, [apiResponseMessage])

  const fileInputRefs = Array.from({ length: steps.length }, () =>
    React.createRef<HTMLInputElement>()
  );

  const handleFileChange =
    (index: number, step: any) =>
      (e: React.ChangeEvent<HTMLInputElement>) => {
        const selectedFile: any = e.target.files && e.target.files[0];
        const acceptedFileExtensions = ['text/csv','.csv', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', 'application/vnd.ms-excel']

        // Check if csv or excel format
        if (!acceptedFileExtensions.includes(selectedFile.type)) {
          e.target.value = '';
          alert("Please select a file of excel format");
        }
        // Check if a file is selected and its size is less than 50 MB
        else if (selectedFile && selectedFile.size <= 50 * 1024 * 1024) {
          setFiles((value) => {
            value[index] = selectedFile
            return value.slice()
          });

          
          step.key !== 'dataset_name' && uploadData(e,selectedFile, step.key, index);
        } else {
          e.target.value = '';
          alert("Please select a file less than 50 MB.");
        }
      };

  const handleCancelFile = (index: number) => {
    const newFiles = [...files];
    const mandatorySection = [0, 1, 3]
    newFiles[index] = null;
    if (fileInputRefs[index].current?.value) {
      fileInputRefs[index].current!.value = ''
    }
    setFiles(newFiles);

    if (mandatorySection.includes(index)) {
      const newIsNextButtonDisabled = [...isNextButtonDisabled];
      newIsNextButtonDisabled[index] = true;
      setIsNextButtonDisabled(newIsNextButtonDisabled);

    }
  };

  const handleNext = () => {
    setCurrentStep((prevStep) => prevStep + 1);
  };

  const isNextButtonDisabledForStep = (index: number) => {
    if (requiredFiles.length === 0) {
      return false; // Enable Next button when all required files are uploaded
    } else {
      return !requiredFiles.every(fileKey => files[steps.findIndex(step => step.key === fileKey)] !== null);
    }
  };

  const handlePrevious = () => {
    setCurrentStep((prevStep) => prevStep - 1);
  };

  const handleSaveDataset = () => {
    SetDatasetName();
    // setDatasetName('')
    // setFiles([
    //   null,
    //   null,
    //   null,
    //   null,
    //   null,
    //   null
    // ])
    // setIsNextButtonDisabled([
    //   true,
    //   true,
    //   false,
    //   true,
    //   false,
    //   false
    // ])
    // setCurrentStep(0);
  };

  const handleStepClick = (index: number) => {
    setCurrentStep(index === currentStep ? -1 : index);
  };


  // console.log("trainer values",trainer?.experimentDetails,trainer?.uploadLoadData)
  return (
    <div className="data-accordian">
      {steps.map((step, index) => (
        <div className="data-step" key={index}>
          <div className="step-header" onClick={() => handleStepClick(index)}>
            <span className="d-flex align-items-center">
              {apiResponseMessage[index] && apiResponseMessage[index].status === 'success' ? (
                <CompleteDataCheck />
              ) : (
                <DataCheck />
              )}
              <h6 className="mb-0">{step.name} <sup>{step.required ? '*' : ''}</sup></h6>

            </span>
            <span className="d-flex align-items-center">
              {
                index !== steps.length - 1 && <Button variant="text" onClick={(e) => {e.stopPropagation();downloadSchema(step.key)} }> <DownloadSchemaIcon className="me-2" /> Download Schema</Button>
              }
            </span>
          </div>
          {currentStep === index && index !== steps.length - 1 && (
            <div className="step-content">
              <div className="position-relative mt-auto">
                <input
                  hidden
                  type="file"
                  accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                  ref={fileInputRefs[index]}
                  onChange={handleFileChange(index, step)}
                />
                <button
                  className="file-btn"
                  onClick={() => fileInputRefs[index].current?.click()}
                >
                  <UploadIcon />
                </button>
              </div>
              <p>{step.description}</p>
              <div className={files[index] && files[index]?.name && apiResponseMessage[index] ?  "success-file": "" }>
                { files[index] && files[index]?.name && apiResponseMessage[index] && (
                  <div>
                    <p className={apiResponseMessage[index].status == 'success' ? 'text-success' : 'text-failed'}>{apiResponseMessage[index].message}</p>
                  </div>
                )}
                {files[index] && files[index]?.name && (
                  <div className="file-name">
                    <p title={files[index]!.name}><FileIcon /> {files[index]!.name}</p>
                    <Button onClick={() => handleCancelFile(index)}>
                      <DeleteIcon />
                    </Button>
                  </div>
                )}
              </div>
              <div className="bottom-btn">
                {index < steps.length - 1 && index !== 0 && (
                  <Button onClick={handlePrevious}>Previous</Button>
                )}
                {index < steps.length - 1 && (
                  <Button
                    onClick={handleNext}
                    disabled={isNextButtonDisabled[index]}
                  >
                    Next
                  </Button>
                )}
              </div>
            </div>
          )}
          {currentStep === index && index === steps.length - 1 && (
            <div className="step-content align-items-center" >
              {apiResponseMessage[index] && apiResponseMessage[index].status === 'success' && (
                <div className="d-flex h-100 mb-0 flex-column justify-content-center align-items-center">
                  <DatasetComplete />
                  <p>{apiResponseMessage[index].message}</p>
                </div>
              )}
              <div className="step-content border-0 d-flex flex-column justify-content-center text-center">
                <input
                  type="text"
                  value={datasetName}
                  placeholder="Enter Dataset Name"
                  onChange={(e) => setDatasetName(e.target.value)}
                  className="form-control mb-2"
                />
                <p>{step.description}</p>
              </div>
              <div className="bottom-btn">
                <Button onClick={handlePrevious}>Previous</Button>
                <Button onClick={handleSaveDataset} disabled={datasetName === "" || !mandatoryUpload}>Save</Button>
              </div>
            </div>
          )}
        </div>
      ))}
    </div>
  );
}
