export enum API_BASE_URL {
  LOCAL = "https://api.mixharbour.triumph.zsservices.com",
}

export enum ApiEndPoints {
  //load-data
  UPLOAD_DATA = API_BASE_URL.LOCAL + "/landing-page/upload-data", //done -> done
  DOWNLOAD_SCHEMA = API_BASE_URL.LOCAL + "/landing-page/download-schema", //done -> issue: //TODO: response curve is giving whole dataset
  SAVE_DATASET = API_BASE_URL.LOCAL + "/landing-page/save-dataset/", //done -> done
  RECENT_UPLOAD = API_BASE_URL.LOCAL +
    "/landing-page/get-latest-saved-dataset/", // done -> done
  HISTORIC_UPLOAD = API_BASE_URL.LOCAL +
    "/landing-page/get-historical-saved-dataset/", // done -> done
  DOWNLOAD_SAVED_DATASET = API_BASE_URL.LOCAL +
    "/landing-page/download-saved-dataset/", //TODO: no api in backend for this
  DELETE_DATASET = API_BASE_URL.LOCAL + "/landing-page/delete-saved-dataset/", //TODO: no api in backend for this
  SET_DATASET = API_BASE_URL.LOCAL + "/landing-page/select-saved-dataset/", // issue: //TODO: api wants id instead of name, iske response mein aaegi process id

  //Dashboard
  DASHBOARD_PROCESS = API_BASE_URL.LOCAL + "/dashboard-page/checkProcessStatus", //done -> tested
  DASHBOARD_FILTER = API_BASE_URL.LOCAL +
    "/dashboard-page/get-unique-geos-channels", //done -> tested
  DASHBOARD_DATASET_LIST = API_BASE_URL.LOCAL +
    "/dashboard-page/get-saved-datasets/", //TODO: remove this api call, and replace with historical waala response
  DASHBAORD_KPIS = API_BASE_URL.LOCAL + "/dashboard-page/kpis", //FAILING for every case
  DASHBOARD_SPEND_BARCHART = API_BASE_URL.LOCAL +
    "/dashboard-page/spends-vs-inc-sales-chart", //FAILING
  DASHBOARD_SPEND_TABLE = API_BASE_URL.LOCAL +
    "/dashboard-page/spends-vs-inc-sales-table",
  DASHBOARD_ROI_BARCHART = API_BASE_URL.LOCAL +
    "/dashboard-page/roi-mroi-chart",
  DASHBOARD_ROI_TABLE = API_BASE_URL.LOCAL + "/dashboard-page/roi-mroi-table",
  DASHBOARD_SPEND_LINECHART = API_BASE_URL.LOCAL +
    "/dashboard-page/response-curve",
  DASHBOARD_ROI_LINECHART = API_BASE_URL.LOCAL + "/dashboard-page/mroi-curve",
  DASHBOARD_RELOAD_API = API_BASE_URL.LOCAL + "/dashboard-page/KPIs",

  //Optimize
  OPTIMIZE_FORM_CONFIG = API_BASE_URL.LOCAL + "/optimize-page/form-config",
  OPTIMIZE_DOWNLOAD_SCHEMA = API_BASE_URL.LOCAL +
    "/optimize-page/download-schema-optimise/",
  OPTIMIZE_UPLOAD_SCHEMA = API_BASE_URL.LOCAL +
    "/optimize-page/upload-file-optimise",
  OPTIMIZE_VALIDATION = API_BASE_URL.LOCAL + "/optimize-page/form-config",
  OPTIMIZE_RUN = API_BASE_URL.LOCAL + "/optimize-page/run-optimizer",

  //Result
  RESULT_TABLE = API_BASE_URL.LOCAL + "/result-page/get-latest-results",
  RESULT_COMPARE_SCENARIOS = API_BASE_URL.LOCAL +
    "/result-page/compare-scenarios",
  RESULT_GENERATE_REPORT = API_BASE_URL.LOCAL + "/result-page/generate-report",
  RESULT_DOWNLOAD_EXPERIMENT = API_BASE_URL.LOCAL +
    "/result-page/download-experiment/",
  RESULT_SPEND_CHART = API_BASE_URL.LOCAL +
    "/result-page/get-spend-chart-data/",
  RESULT_ROI_CHART = API_BASE_URL.LOCAL + "/result-page/get-roi-chart-data/",
  RESULT_MARKETING_CHART = API_BASE_URL.LOCAL +
    "/result-page/get-marketing-chart-data/",
  RESULT_SPEND_TABLE = API_BASE_URL.LOCAL +
    "/result-page/get-spend-table-data/",
  RESULT_ROI_TABLE = API_BASE_URL.LOCAL + "/result-page/get-roi-table-data/",
  RESULT_MARKETING_TABLE = API_BASE_URL.LOCAL +
    "/result-page/get-marketing-table-data/",
  RESULT_DOWNLOAD_MARKETING_CALENDAR = API_BASE_URL.LOCAL +
    "/result-page/download-marketing-calendar",
  RESULT_KPI_RESULTS = API_BASE_URL.LOCAL + "/result-page/get-kpi-results",
  RESULT_FILTERS = API_BASE_URL.LOCAL + '/result-page/get-unique-geos-channels'
}
