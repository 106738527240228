import React from "react";
import { OPTIMIZE_FORM_CONFIG, OPTIMIZE_MAXIMUM_FROM_CONFIG, OPTIMIZE_TARGET_FORM_CONFIG } from "../../../../assets/data/json";
import { Services } from "../../../shared/services";
import { useAuth } from "../../../../utils/auth/auth.guard";
import { IformConfig } from "../../../../utils/interfaces/interface";
import CustomForm from "../../components/custom-form/custom-form";
import {FixBudget,TargetAttainment,MaximumAttainment } from "../../../../assets/icons/Icons";
import "./optimize.scss";
import { CircularProgress } from "@mui/material";
import { ApiEndPoints } from "../../../../utils/enums/apiendpoints";
import { useTrainer } from "../../services/trainer.service";
import { useNavigate } from "react-router-dom";

export default function Optimize() {
  const [currentSection, setSelectedSection] = React.useState<string>("Budget Optimization");
  const [formConfig, setFormConfig] = React.useState<any>();
  const [callGeoChannelApi, setCallGeoChannelApi] = React.useState<boolean>(false);


  const steps = ["Budget Optimization", "Target Attainment", "Maximum Attainment"];
  const AppService = React.useContext(Services.AppService);
  const auth = useAuth()!;
  const trainer = useTrainer();
  const navigate = useNavigate();


  React.useEffect(() => {
  //  console.log('dataset name: ',trainer.datasetName)
    if (trainer.datasetName == '') {
      navigate('/load-data')
    }
  },[])
  
  React.useEffect(() => {
    if (trainer.datasetName != '') {
      getFormConfig();
    }
  }, [currentSection])

  React.useEffect(() => {
    if (callGeoChannelApi) {
      getGeoChannelData()
    }
  }, [callGeoChannelApi])


  const getFormConfig = async () => {
    setFormConfig(null);
    AppService.API_HANDLER({
      auth,
      requestMethod: "post",
      url: ApiEndPoints.OPTIMIZE_FORM_CONFIG + `/?scenario_name=${currentSection === 'Budget Optimization' ? 'fixed_budget' : currentSection === 'Target Attainment' ? 'target_attainment' : 'maximum_attainment'}`,
      payload: {
        dataset_id: trainer.datasetName,
        dataset_name: trainer.savedDatasets[0].options.find((d: any) => d.value == trainer.datasetName).title,
      },
      useGlobalLoader: false,
      showErrorMessage: true,
      showSuccessMessage: false,
      fetchFromApi: false,
      LOCAL_RESPONSE: currentSection === 'Budget Optimization' ? OPTIMIZE_FORM_CONFIG : currentSection === 'Target Attainment' ? OPTIMIZE_TARGET_FORM_CONFIG : OPTIMIZE_MAXIMUM_FROM_CONFIG ,
      successCb: (res: any) => {
        setCallGeoChannelApi(true);
        setFormConfig(res.data);
      },
      failedCb: () => {
      },
    });
  };
  const getGeoChannelData = async () => {
    AppService.API_HANDLER({
      auth,
      requestMethod: "post",
      url: ApiEndPoints.DASHBOARD_FILTER + `/${trainer.datasetName}`,
      payload: {},
      useGlobalLoader: false,
      showErrorMessage: true,
      showSuccessMessage: false,
      fetchFromApi: true,
      LOCAL_RESPONSE: {},
      successCb: (res: any) => {
        setCallGeoChannelApi(false);
        // console.log(res)
        let currentSectionKey = ''
        if (currentSection === 'Budget Optimization') {
          currentSectionKey = 'fixed_budget'
          const channelInput = formConfig[currentSectionKey][currentSectionKey+'_form_config'].find((input: any) => input.key =='channels')
          // console.log(channelInput)
          channelInput.options = res.data.channels.map((channel: string) => {
            return {
              title: channel,
              value: channel
            }
          })
          const geoInput = formConfig[currentSectionKey][currentSectionKey+'_form_config'].find((input: any) => input.key =='geos')
          geoInput.options = res.data.geos.map((geo: string) => {
            return {
              title: geo,
              value: geo
            }
          })
          // console.log(formConfig);
          setFormConfig({...formConfig})
        } else if (currentSection === 'Target Attainment') {
          currentSectionKey = 'target_attainment'
          const channelInput = formConfig[currentSectionKey][currentSectionKey+'_form_config'].find((input: any) => input.key =='channels')
          // console.log(channelInput)
          channelInput.options = res.data.channels.map((channel: string) => {
            return {
              title: channel,
              value: channel
            }
          })
          const geoInput = formConfig[currentSectionKey][currentSectionKey+'_form_config'].find((input: any) => input.key =='geos')
          geoInput.options = res.data.geos.map((geo: string) => {
            return {
              title: geo,
              value: geo
            }
          })
          // console.log(formConfig);
          setFormConfig({...formConfig})
        } else {
          currentSectionKey = 'maximum_attainment'
          const channelInput = formConfig[currentSectionKey]['second_section'].find((input: any) => input.key =='channels')
          // console.log(channelInput)
          channelInput.options = res.data.channels.map((channel: string) => {
            return {
              title: channel,
              value: channel
            }
          })
          const geoInput = formConfig[currentSectionKey]['second_section'].find((input: any) => input.key =='geos')
          geoInput.options = res.data.geos.map((geo: string) => {
            return {
              title: geo,
              value: geo
            }
          })
          // console.log(formConfig);
          setFormConfig({...formConfig})
        }
        
      },
      failedCb: () => {
      },
    });
  };
 

  return (
    <>
    <section className="optimize-page">
    <div  className="d-flex optimize-step">
      {steps.map((step) => (
        <div onClick={() => setSelectedSection(step)} className= {`step ${step === currentSection  ? "active-step" : ""}`}>
           {step === 'Budget Optimization' ? <FixBudget /> : step === 'Target Attainment' ? < TargetAttainment/> : <MaximumAttainment /> } <p>  {step}</p>
        </div>
      ))}
    </div>
      <div>
        {currentSection === "Budget Optimization" ?
        <>
         {formConfig && formConfig.fixed_budget && !callGeoChannelApi ? 
         <CustomForm currentSection={currentSection} sectionName={formConfig.fixed_budget.section} formData={formConfig.fixed_budget.fixed_budget_form_config} secondSection = {formConfig.fixed_budget.radio_option} /> 
         : <div className="page-loader"><CircularProgress /></div>
         }
        </>
         : null}
        {currentSection === "Target Attainment" ? (
           <>
           {formConfig && formConfig.target_attainment && !callGeoChannelApi ? 
           <CustomForm currentSection={currentSection} sectionName={formConfig.target_attainment.section} formData={formConfig.target_attainment.target_attainment_form_config} secondSection = {formConfig.target_attainment.radio_option}/> 
           : <div className="page-loader"><CircularProgress /></div>
           }
          </>
        ) : null}
        {currentSection === "Maximum Attainment" ? (
          <>
          {formConfig && formConfig.maximum_attainment && !callGeoChannelApi ? 
          <CustomForm currentSection={currentSection} sectionName={formConfig.maximum_attainment.section} formData={formConfig.maximum_attainment.maximum_attainment_form_config} maximumAtt={formConfig.maximum_attainment.second_section} 
                formConfigDict={formConfig}
          /> 
          : <div className="page-loader"><CircularProgress /></div>
          }
         </>
        ) : null}
      </div>
    </section>
    
    </>
  );
}