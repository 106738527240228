// /src/hooks/useAuth.tsx
import React, { useState, createContext, useContext } from "react";
import { ConfirmationDialogProps, GenericObject, SnackbarProps } from "../interfaces/interface";
import { CircularProgress } from "@mui/material";
import ConfirmationDialog from "../../modules/shared/components/ConfirmationDialog";
import SimpleSnackbar from "../../modules/shared/components/SimpleSnackBar";

// Create the context 
const AuthContext = createContext<any>(null);

export const AuthProvider = ({ children }: { children: React.ReactElement }) => {

    const userData = {
        id: "jd\\15612",
        name: "John Doe",
        firstName: "John",
        lastName: "Doe",
        isAdmin: false,
        roles: ["User", "Admin"],
    }

    const API_MODE = true;

    const [authed, setAuthed] = useState<boolean>(API_MODE ? false : true);
    const [userDetails, setUserDetails] = useState<GenericObject | null>(API_MODE ?  null : userData);

    const [loading, setLoading] = useState<boolean>(false);
    const [dialogDetails, setDialogDetails] = useState<ConfirmationDialogProps | null>(null);
    const [snackBarDetails, setSnackBarDetails] = useState<SnackbarProps | null>(null);

    const LOCAL_RESPONSE_TIMEOUT = 0;

    const login = async (data: any): Promise<void> => {
        setUserDetails(data);
        setAuthed(true);
    };

    const logout = () => {
        setAuthed(false);
        setUserDetails(null);
        window.location.href = "https://idm.zsservices.com/Triumph/IdentityManager/app/Web/logout.aspx"
    };

    async function getUserDetails() {
        localStorage.removeItem('_vrtu');
        const userSessionRes= await fetch("https://api.mixharbour.triumph.zsservices.com/user-session",{method:"GET",credentials: 'include'});
      //  console.log(userSessionRes)
        if (userSessionRes.status==200){
            const data = await userSessionRes.json();
            setAuthed(true)
            setUserDetails(data);
            // console.log({data})
            return data
        } else {
            setAuthed(false);
            setUserDetails(null);
          //  console.log(window.location.pathname)
            if (!window.location.pathname.startsWith("/login")) {
                window.location.href = window.location.origin + "/login";
            }
            return false;
        }
    }

    return (
        <AuthContext.Provider
            value={{
                authed,
                setAuthed,
                userDetails,
                API_MODE,
                LOCAL_RESPONSE_TIMEOUT,
                login,
                logout,
                setLoading,
                setDialogDetails,
                setSnackBarDetails,
                getUserDetails
            }}>
            {children}
            {
                dialogDetails
                    ? (
                        <ConfirmationDialog
                            open={Boolean(dialogDetails)}
                            close={() => setDialogDetails(null)}
                            successCB={dialogDetails!.successCB}
                            failedCB={dialogDetails!.failedCB}
                            title={dialogDetails!.title}
                            description={dialogDetails!.description}
                            isAlert={Boolean(dialogDetails!.alert)}
                        />
                    )
                    : null
            }
            {
                snackBarDetails
                    ? (
                        <SimpleSnackbar
                            open={Boolean(snackBarDetails)}
                            message={snackBarDetails.message}
                            severity={snackBarDetails.type}
                            handleClose={() => setSnackBarDetails(null)}
                        />
                    )
                    : null
            }
            {
                loading
                    ? (
                        <div className="page-loader">
                            <CircularProgress />
                        </div>
                    )
                    : null
            }
        </AuthContext.Provider>
    );
};

export const useAuth = () => useContext(AuthContext);