export const data = {
  status: "200",
  message: "successfully featch the data.",
  data: {
    table_headers: [
      {
        id: 1,
        name: "Channel",
        key: "channel",
        hidden: false,
        isSort: true,
      },
      {
        id: 2,
        name: "Aug 23",
        key: "Aug23",
        hidden: false,
        isSort: true,
      },
      {
        id: 3,
        name: "Sept 23",
        key: "Sept23",
        hidden: false,
        isSort: true,
      },
      {
        id: 4,
        name: "Oct 23",
        key: "Oct23",
        hidden: false,
        isSort: true,
      },
      {
        id: 5,
        name: "Nov 23",
        key: "Nov 23",
        hidden: false,
        isSort: true,
      },
      {
        id: 6,
        name: "Dec 23",
        key: "Dec23",
        hidden: false,
        isSort: true,
      },
      {
        id: 7,
        name: "Jan 24",
        key: "Jan24",
        hidden: false,
        isSort: true,
      },
    ],
    table_data: [
      {
        Aug23: 10,
        Sept23: 5,
        Oct23: 59,
        Nov23: 57,
        Dec23: 86,
        Jan24: 21,
        channel: "TV",
      },
      {
        Aug23: 20,
        Sept23: 53,
        Oct23: 50,
        Nov23: 52,
        Dec23: 78,
        Jan24: 28,
        channel: "OOH",
      },
      {
        Aug23: 10,
        Sept23: 5,
        Oct23: 47,
        Nov23: 53,
        Dec23: 106,
        Jan24: 41,
        channel: "Google",
      },
      {
        Aug23: 10,
        Sept23: 0,
        Oct23: 54,
        Nov23: 56,
        Dec23: 92,
        Jan24: 73,
        channel: "Meta",
      },
      {
        Aug23: 10,
        Sept23: 15,
        Oct23: 57,
        Nov23: 69,
        Dec23: 92,
        Jan24: 99,
        channel: "OTT",
      },
      {
        Aug23: 82,
        Sept23: 5,
        Oct23: 60,
        Nov23: 63,
        Dec23: 103,
        Jan24: 144,
        channel: "Print",
      },
      {
        Aug23: 60,
        Sept23: 55,
        Oct23: 65,
        Nov23: 60,
        Dec23: 106,
        Jan24: 249,
        channel: "Digital",
      }
    ]
  }
};
