import { Box } from "@mui/material";
import { Outlet } from "react-router-dom";
import { ResultOutput } from "../../components/result-output/result-ouput";

export default function ResultPage() {
  return (
    <Box>
      <Outlet />
      <ResultOutput />
    </Box>
  );
}
