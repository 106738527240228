export enum ApiEndPoints {
  FETCH_LOAD_DATA = "",
}

export enum ApiStatus {
  Success = "success",
  Failed = "failed",
}

export enum AppRoutes {
  Dashboard = "dashboard",
  Login = "login",
  LoadData = "load-data",
  Optimize = "optimize",
  Result = "result",
  LandingDashboard = "landing-dashboard",
}

export enum TableEvents {
  CheckboxSelection = "selection",
  RowSelection = "rowClick",
  RowExpand = "rowExpand",
}

export enum CustomColumns {
  Settings = "settings",
  Checkbox = "checkbox",
  Accordion = "accordion",
  DataDistribution = "data_distribution",
}

export enum AppSettings {
  DebounceDuration = 500,
  DateFormat = "D MMM ['YY]",
  ApiDateFormat = "YYYY-MM-DD",
}

export enum ResultsUnit {
  Unit = "CHF", // ₹
}
