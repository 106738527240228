import { Box } from "@mui/material";
import { Outlet } from "react-router-dom";
import { TrainerProvider } from "../../services/trainer.service";
import { Header } from "../../components/header/header";
import { ResultScreen } from "../../components/result/result";

export default function Result() {
  return (
    <Box>
      <Outlet />
      <ResultScreen />
    </Box>
  );
}
